.payent-sec-divv-pppx {
  display: flex;
  gap: 20px;
}

.Calender-and-date-span-divv {
  display: flex;
  gap: 10px;
}

.Payment-all-sec-main-divv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.written-span-date-edit-spann {
  font-size: 17px;
  font-weight: 500;
}

.date-edit-spann {
  color: #706c74;
}

.Edit-date-edit-spann {
  color: #00ad5a;
  font-weight: 500;
}
.Date-spann-divv {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.Imgg-Greencard-number-divv {
  border-radius: 10px;
  border: 1px solid transparent;
  overflow: hidden;
  position: relative;
}
.Master-card-main-divv {
  position: absolute;
  top: 20px;
  right: 20px;
}
.number-of-card-divv-ppx {
  position: absolute;
  top: 75px;
  left: 20px;
}
.Number-spann-cardd {
  font-weight: 600;
  font-size: 20px;
  color: white;
}
.Number-Divv-spann-cardd {
  display: flex;
  gap: 25px;
}
.Jane-cooper-card-main-divv {
  position: absolute;
  bottom: 35px;
  left: 20px;
}
.Jane-cooper-span {
  font-weight: 500;
  color: #fff;
}
.Expiry-card-main-divv {
  position: absolute;
  bottom: 35px;
  right: 35px;
}
.Expiry-divv-span {
  font-size: 14px;
  color: #fff;
}
.nine-twenty-main-divv {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.Nine-twenty-five-divv-span {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}
.Two-Radio-btnn-divv-Main {
  display: flex;
  gap: 2rem;
}
.Radio-btnn-divv-imgg {
  cursor: pointer;
  display: flex;
  gap: 10px;
}

.Four-Card-radio-buttons {
  display: flex;
  gap: 15px;
  flex-direction: column;
}
.Credit-card-divv-main {
  font-weight: 500;
}
.Radio-btnn-divv-imgg > input[type="radio"] {
  transform: scale(2);
}

.Expiry-of-Addresss-main {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.Expiryy-dropdown-city {
  width: 105px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Expiry-Adddress-divv-Dropdown {
  width: 80px;
  height: 40px;
  outline: none;
  border: none;
  background: transparent;
}

.Input-cvv-feild {
  width: 80px;
  height: 45px;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  outline: none;
  padding-left: 13px;
  box-sizing: border-box;
}

.Expiry-month-year-cvv-main-divv {
  display: flex;
  gap: 20px;
}
.check-book-btnn {
  background: #00ad5a;
  border-radius: 6px;
  width: 330px;
  height: 45px;
  color: white;
  font-weight: 700t;
  font-size: 15px;
  border: none;
  outline: none;
}

.Book-check-next-btnn-divv {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

@media screen and (max-width: 360px) {
  .Greencard-imgg {
    width: 320px;
  }
  .Radio-button-divv-ppx {
    padding-left: 18px;
  }
  .Expiry-month-year-cvv-main-divv {
    gap: 10px;
  }
  .Book-check-next-btnn-divv {
    padding-right: 18px;
  }
  .check-book-btnn {
    width: 311px;
  }
  .Credit-card-divv-main {
    font-size: 14px;
  }
}

@media screen and (max-width: 320px) {
  .Greencard-imgg {
    width: 280px;
  }
  .payent-sec-divv-pppx {
    justify-content: center;
    align-items: center;
    width: 80%;
    gap: 1px;
  }
  .number-of-card-divv-ppx {
    top: 54px;
  }
  .Number-Divv-spann-cardd {
    gap: 16px;
  }
  .Two-Radio-btnn-divv-Main {
    gap: 1rem;
  }
  .Radio-button-divv-ppx {
    padding-left: 45px;
  }
  .Radio-btnn-divv-imgg > input[type="radio"] {
    transform: scale(1.5);
  }
  .fifth-input-feild {
    width: 280px;
  }
  .Expiryy-dropdown-city {
    width: 100px;
  }
  .Input-cvv-feild {
    width: 65px;
  }
}
