.BookProject-Main-divv {
  height: 100%;
  width: 100%;
  background-color: #f3f3f3;
  padding-bottom: 30px;
  justify-content: center;
}
.book-sec-divv-ppx {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.book-a-project-heading-divv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 480px;
}
.Share-Within-Compare-main {
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #00ad5a;
  border-radius: 40px;
}
.Share-and-para-divv {
  display: flex;
  height: 100px;
  width: 470px;
  align-items: center;
}
.heading-off-book {
  font-size: 24px;
  font-weight: 500;
}
.para-span-tagg-ppx {
  color: #666;
  font-size: 15x;
}
.para-of-submit-divv-ppx {
  width: 242px;
  text-align: center;
}
.underline-share-boxx-divv {
  width: 300px;
  border: 3px solid black;
}
.Share-and-Underline-divv {
  height: 5px;
  background-color: #cecece;
  width: 500px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.All-three-share-man-hand {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.all-three-Text-divv {
  display: flex;
  gap: 13rem;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.Share-text-main-divv {
  width: 250px;
  text-align: center;
}
.text-share-yahi-hai {
  color: #666;
}
.Enter-zipcode-main-divv {
  width: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.Zipcode-para-textbox-divv {
  width: 50%;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.Enter-zipcodee-full-divv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}
.input-btnn-main-divv {
  display: flex;
  gap: 8px;
}
.Heading-span-tagg-ppx {
  font-size: 20px;
  font-weight: 500;
}
.Para-Heading-divv-Enter {
  display: flex;
  justify-content: center;
}
.Para-Text-divv-We {
  width: 620px;
}
.Para-we-span-tagg-ppx {
  font-size: 15px;
  display: flex;
  justify-content: center;
}
.text-box-input-zipcode {
  width: 220px;
  height: 40px;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  padding-left: 8px;
}
.Srch-button-style {
  width: 100px;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: #00ad5a;
  color: #fff;
  font-weight: 500;
}
@media screen and (max-width: 885px) {
  .Share-and-para-divv {
    width: 270px;
  }
  .all-three-Text-divv {
    gap: 1rem;
  }
  .book-a-project-heading-divv {
    width: 305px;
  }
  .Enter-zipcode-main-divv {
    width: 75%;
  }
}
@media screen and (max-width: 768px) {
  .Enter-zipcode-main-divv {
    width: 78%;
  }
}
@media screen and (max-width: 429px) {
  .all-three-Text-divv {
    gap: 26px;
  }
  .Share-and-para-divv {
    width: 160px;
  }
  .book-a-project-heading-divv {
    width: 220px;
  }
  .Enter-zipcode-main-divv {
    width: 88%;
  }
  .Para-Text-divv-We {
    width: 370px;
    text-align: center;
  }
  .Para-we-span-tagg-ppx {
    font-size: 14px;
  }
}

@media screen and (max-width: 415px) {
  .Share-text-main-divv {
    width: 114px;
  }
  .Share-and-para-divv {
    width: 142px;
  }
  .Heading-span-tagg-ppx {
    font-size: 18px;
  }
}

@media screen and (max-width: 376px) {
  .Srch-button-style {
    width: 80px;
  }
}

@media screen and (max-width: 360px) {
  .Share-and-para-divv {
    width: 130px;
  }
  .Para-Text-divv-We {
    width: 314px;
  }
  .text-box-input-zipcode {
    width: 180px;
  }
  .Srch-button-style {
    width: 75px;
  }
  .BookProject-Main-divv {
    height: 100%;
  }
}
@media screen and (max-width: 320px) {
  .BookProject-Main-divv {
    height: 100%;
  }
  .text-share-yahi-hai {
    font-size: 13px;
  }
  .Share-and-para-divv {
    width: 115px;
  }
  .all-three-Text-divv {
    gap: 9px;
  }
  .Para-Heading-divv-Enter {
    width: 162px;
  }
  .Para-we-span-tagg-ppx {
    font-size: 12px;
  }
  .Para-Text-divv-We {
    width: 265px;
  }
}
