.main_send_otp_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.otp_sub_main_div {
  width: 100%;
  padding: 2rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.otp_sub_main_div > p {
  font-size: 15px;
  text-align: center;
}
