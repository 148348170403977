.main_Dasbord_section {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6rem 3rem;
}
.sub_dasbord_section {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;

  padding: 1.5rem 5rem;
}
.dasbord_inner_box {
  width: 90%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
}

.dashbord_heading_div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  width: 95%;
}

.dashbord_heading_div > h1 {
  color: var(--Black, #14161b);
  font-family: Be Vietnam Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px; /* 137.5% */
}
/* noData_div */
.noData_div {
  width: 100%;
  height: 50vh;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.noData_div > h1 {
  color: var(--Black, #5b5b5b);
  font-family: Be Vietnam Pro;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px; /* 137.5% */
}
.noData_div > p {
  color: var(--Black, #5b5b5b);
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px; /* 137.5% */
}
.dashbord_heading_div > h4 {
  color: var(--Black, #14161b);
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
}
.main_btn_box {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading_btn_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-bottom: 2px solid #ece7fa;
}
.das_btn {
  width: 330px;
  height: 45px;
  flex-shrink: 0;
  border: none;
  outline: none;
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* new booking screen  */
.newbooking_main_section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.new_Booking_inner_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 100%;
}
.new_booking_card_div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.new_booking_card {
  width: 360px;
  height: 209px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  background: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  gap: 1rem;
}
.card_head_box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

.card_img_box {
  width: 57px;
  height: 57px;
  flex-shrink: 0;
  border-radius: 58.462px;
  /* background-color: #035858; */
  overflow: hidden;
}
.card_img_box > img {
  width: 100%;
  aspect-ratio: 3/3;
  object-fit: fill;
}
.card_info_box {
  width: 80%;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 1rem;
}
.card_info_box > h2 {
  color: var(--Black, #14161b);
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.date_time_box {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 1rem;
}

.date_box > h5,
.time_box > h5 {
  color: var(--Grey, #706c74);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.date_box > p,
.time_box > p {
  color: var(--Black, #14161b);
  font-family: Be Vietnam Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.card_chat_icon {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.card_btn_box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.card_btn_1 {
  width: 160px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--Green, #00ad5a);
  background: #fff;
  color: #00ad5a;
  outline: none;
}
.card_btn_1:hover {
  background: var(--Green, #00ad5a);
  color: #ffffff;
  transition: all 0.2s;
}
.card_btn_2:hover {
  border: 1px solid var(--Green, #00ad5a);
  background: #fff;
  color: #00ad5a;
  transition: all 0.2s;
}

.card_btn_2 {
  width: 160px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--Green, #00ad5a);
  color: #ffffff;
  outline: none;
  border: none;
}
.card_bottom_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card_bottom_box > h4 {
  color: var(--Green, #00ad5a);
  text-align: right;
  font-family: Be Vietnam Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.model_opne_btn {
  color: var(--Green, #00ad5a);
  text-align: right;
  font-family: Be Vietnam Pro;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;
}
/* Upcoming div  */
.upcoming {
  border-radius: 3px;
  background: #1632c6;
  color: #fff;
  font-family: Be Vietnam Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 3px 7px;
}

.new_Upcoming_card {
  width: 360px;
  height: 209px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  background: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
}
.card_info_box_Up {
  width: 80%;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}
/* Completed div */
.completed_card {
  width: 360px;
  height: 149px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  background: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
}
.Completed {
  border-radius: 3px;
  background: #2ea63a;
  color: #fff;
  font-family: Be Vietnam Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 3px 7px;
}

.card_info_box_Up > h2 {
  color: var(--Black, #14161b);
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.card_chat_icon_com > h4 {
  color: var(--Green, #00ad5a);
  text-align: right;
  font-family: Be Vietnam Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.card_chat_icon_com {
  position: absolute;
  right: 0;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .sub_dasbord_section {
    width: 100%;
    padding: 1.5rem 0rem;
  }
  .dasbord_inner_box {
    width: 100%;
  }

  .das_btn {
    width: 220px;
  }
  .new_booking_card_div {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }
}
@media screen and (max-width: 600px) {
  .main_Dasbord_section {
    padding: 6rem 0rem;
  }
  .das_btn {
    width: 110px;
  }
  .new_Booking_inner_box {
    width: 100%;
  }
  .new_booking_card_div {
    grid-template-columns: repeat(1, 1fr);
  }
  .noData_div {
    width: 90%;
    height: 30vh;
  }
  .noData_div > h1 {
    font-size: 20px;
  }
  .noData_div > p {
    color: var(--Black, #5b5b5b);
    font-family: Be Vietnam Pro;
    font-size: 14px;
    text-align: center;
  }
}
