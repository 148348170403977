.Settings-Mainn-Divv {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #ebebeb;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 4rem;
}

.Settings-secondary-Divv {
  width: 100%;
}
/* RoundSwitchButton.css */

.round-switch-button {
  width: 48px;
  height: 28px;
  background-color: #dedede;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  transition: background-color 0.3s;
  border: none;
  outline: none;
}

.slider {
  width: 21px;
  height: 21px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s, background-color 0.3s;
}

.round-switch-button.active {
  background-color: #00ad5a;
}

.slider.active {
  transform: translateX(22px);
  background-color: #fff;
}

.Settings-Main-Span-divv {
  height: 60px;
}

.Settikgs-main-span {
  font-size: 25px;
  font-weight: 500;
}

.Spann-Off-email {
  font-weight: 500;
}

.Underline-Divv-Email {
  border: 1px solid #ebebeb;
}

.Email-Notification-divv {
  /* width: 428px; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SMS-Notification-divv {
  /* width: 428px; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Email-and-SmS-Main_divv {
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  gap: 4rem;
}

.Email-with-underline-Divv {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media screen and (max-width: 885px) {
  .Email-and-SmS-Main_divv {
    width: 300px;
    flex-wrap: wrap;
  }
  .Settings-Mainn-Divv {
    padding: 2rem;
  }
  .Email-Notification-divv {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .SMS-Notification-divv {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 600px) {
  .Email-and-SmS-Main_divv {
    width: 230px;
  }
  .Email-Notification-divv {
    width: 230px;
  }
  .SMS-Notification-divv {
    width: 230px;
  }
  .Settings-Mainn-Divv {
    padding: 1rem;
  }
  .Email-with-underline-Divv {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .Email-Notification-divv {
    width: 200px;
  }
  .SMS-Notification-divv {
    width: 200px;
  }
}
