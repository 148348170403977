.main_contact_section {
  width: 100%;
  height: max-content !important;
  padding: 5rem 0rem 1rem 0rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub_contact_div {
  width: 90vw;
  height: max-content !important;
  display: grid !important;
  grid-template-rows: 60% 40% !important;
}
.comtact_info_div {
  width: 90%;
  height: 100%;
  padding: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
}
.contact_form_div {
  width: max-content;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
  /* background-color: cadetblue; */
}

.contact_form_div > h1 {
  color: #14161b;
  font-family: Be Vietnam Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px; /* 137.5% */
}

/* from styling start here */

.contactUS_form {
  padding: 0rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
}
.Name_box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.Fname_box,
.Email_box {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}
.Fname_box label,
.Email_box label {
  color: #14161b;
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Fname_input_box,
.Email_input_box {
  width: 223px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  background: #fff;
}
.Fname_input_box > input,
.Email_input_box > input {
  padding: 10px;
  width: 95%;
  height: 95%;
  border: none;
  outline: none;
  background-color: transparent;
}
.PhoneNumber_div,
.Message_box {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}
.PhoneNumber_div > label,
.Message_box > label {
  color: #14161b;
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Phonenumber_input_box {
  width: 466px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  background: #fff;
}
.Phonenumber_input_box > input {
  padding: 10px;
  width: 95%;
  height: 95%;
  border: none;
  outline: none;
  background: transparent;
}
.Message_input_box {
  width: 466px;
  height: 132px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  background: #fff;
}
.Message_input_box > textarea {
  width: 95%;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  resize: none;
  padding: 10px;
}
.sub_btn {
  width: 154px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #00ad5a;
  border: none;
  outline: none;
  color: #fff;
  font-family: Be Vietnam Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* form styling end  */
.contact_details_box {
  width: max-content;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
}
.contact_details_box > h1 {
  color: var(--Black, #14161b);
  font-family: Be Vietnam Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px; /* 137.5% */
}
.contact_details_box > p {
  width: 606px;
  height: 66px;
  flex-shrink: 0;
  color: #706c74;

  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.contact_info_details {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.adress_box {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 1rem;
}
.adress_box > p {
  color: #706c74;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Email_boxx {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 1rem;
}
.Email_boxx > a {
  color: #706c74;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.PHone_box {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 1rem;
}
.PHone_box > .call_box > a {
  color: #706c74;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.map_box {
  width: 100%;
  height: max-content !important;
  overflow: hidden;
  background-color: #00ad5a;
}
.map_sec_divv {
  width: 100%;
  height: 100%;
}
/*  TABLET AND   */
@media screen and (max-width: 1024px) {
  .comtact_info_div {
    flex-direction: column;
  }
  .map_box {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .map_box > img {
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: cover;
  }
}

@media screen and (max-width: 885px) {
  .map_box {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding-left: 4.5rem;
  }
}
@media screen and (max-width: 440px) {
  .map_box {
    padding-left: 3rem;
  }
  .main_contact_section {
    /* height: 100% !important; */
    margin-top: 6rem;
  }
}
@media screen and (max-width: 600px) {
  .Name_box {
    flex-direction: column;
  }
  .Phonenumber_input_box {
    width: 223px;
  }
  .Message_input_box {
    width: 223px;
  }
  .contact_details_box > p {
    width: 300px;
    height: auto;
  }
  .adress_box {
    width: 280px;
    align-items: flex-start;
  }
}
