.edit-profile-main-section {
  width: max-content;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #ffffff;
  padding: 2rem 4rem;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.edit-profile-main-div {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.Edit-Profile-span23 {
  font-size: 27px;
  font-weight: 700;
  color: #1e2d38;
}

.camimg2-img {
  width: 147px;
  height: 147px;
}

.camimg1-img {
  width: 38px;
  height: 38px;
}

.edit-profile-child27-div {
  position: relative;
  height: 100%;
}

.camimg11-div11 {
  position: absolute;
  top: 7rem;
  height: 100%;
  bottom: 0;
  right: 10px;
}

.edit-profile-main27-div27 {
  display: flex;
  gap: 4rem;
  /* justify-content: center; */
}

.kathryn-input22 {
  width: 253px;
  height: 45px;
  border-radius: 6px;
  border: 1px solid #dbdde2;
  padding-left: 1rem;
  outline: none;
}

.input-text-div-dad-div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.input-text-div-pap-div {
  display: flex;
  gap: 1.5rem;
}

.kathryn-input22::placeholder {
  font-size: 15px;
  font-weight: 600;
  color: #1e2d38;
}

.kathryn-second-input {
  border: 1px solid #dbdde2;
  width: 530px;
  height: 45px;
  border-radius: 6px;
  padding-left: 1rem;
  outline: none;
}

.kathryn-second-input::placeholder {
  font-size: 15px;
  font-weight: 600;
  color: #1e2d38;
}

.save-button25 {
  width: 243px;
  height: 45px;
  background-color: #00ad5a;
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.html12-label-span {
  font-size: 14px;
  font-weight: 500;
  color: #1e2d38;
}

.label-or-feild-div24 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label-or-feild-div24-90 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.html12-label-span-75 {
  font-size: 14px;
  font-weight: 500;
  color: #1e2d38;
}

@media screen and (max-width: 885px) {
  .edit-profile-main27-div27 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  .input-text-div-pap-div {
    display: flex;
    flex-direction: column;
  }

  .input-text-div-dad-div {
    gap: 1rem;
  }

  .kathryn-second-input {
    width: 290px;
  }

  .kathryn-input22 {
    width: 390px;
  }

  .save-button25 {
    width: 290px;
  }
}

@media screen and (max-width: 768px) {
  .edit-profile-main-section {
    padding: 1rem 1rem;
  }
  .edit-profile-main27-div27 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .input-text-div-pap-div {
    display: flex;
    flex-direction: column;
  }

  .kathryn-second-input {
    width: 100%;
  }

  .kathryn-input22 {
    width: 350px;
  }

  .save-button25 {
    width: 350px;
  }
  .Edit-Profile-span23 {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
}

@media screen and (max-width: 428px) {
  .edit-profile-main27-div27 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .input-text-div-pap-div {
    display: flex;
    flex-direction: column;
  }

  .kathryn-second-input {
    width: 100%;
  }

  .kathryn-input22 {
    width: 250px;
  }

  .save-button25 {
    width: 295px;
  }
}
