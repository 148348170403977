.Availability-line-ho {
  border: 1px solid #ebebeb;
  width: 70%;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 21px !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.2rem !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: limegreen !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected :hover {
  background-color: #00ad5a !important;
  color: #fff !important;
  border-radius: 25px !important;
}

.Whole-Month-and-togle-divv {
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}

.Select-Time-Spannn-tagg {
  font-size: 18px;
  font-weight: 600;
}

.Whole-unavail-spaan-tagg {
  font-weight: 600;
  font-size: 18px;
}

.Select-Time-sppan-mainn {
  width: 350px;
}

.Dropp_div {
  width: 160px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Dropp_div > select {
  width: 130px;
  height: 40px;
  outline: none;
  border: none;
  background: transparent;
  color: #000000;
}

.Dropp_div > input {
  width: 180px;
  height: 40px;
  outline: none;
  border: none;
  background: transparent;
}

.Drop-Down-Eight-and-Nine {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.Tooo-Time-spann {
  font-weight: 500;
}

.Unavail_and_Avail_Main_Divv {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.UnAvail_btnn_divv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Unavil_btnn_main {
  width: 160px;
  height: 50px;
  color: #00ad5a;
  background-color: #fff;
  font-weight: 600;
  border-radius: 4px;
  border: 1px solid #00ad5a;
}

.Unavil_btnn_main:hover {
  color: #fff;
  background-color: #00ad5a;
}
.avil_btnn_main {
  width: 160px;
  height: 50px;
  color: #fff;
  background-color: #00ad5a;
  font-weight: 600;
  border-radius: 4px;
  border: 1px solid #00ad5a;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 25px !important;
  background-color: #f0f0f0;
}
.avil_btnn_main:hover {
  color: #00ad5a;
  background-color: #fff;
  border: 1px solid #00ad5a;
}
.react-datepicker__day--disabled {
}

@media screen and (max-width: 428px) {
  .Dropp_div {
    width: 123px;
  }
  .Unavil_btnn_main {
    width: 130px;
  }
  .avil_btnn_main {
    width: 130px;
  }
  .Select-Time-sppan-mainn {
    width: 290px;
  }
}
