.main_register_div {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 6rem 2rem;
}
.Ht-ml-for-imput {
  background: #f9f7fb;
  /* margin-top: 1rem;  */
  /* border: 1px solid #9C04F5; */
  border-radius: 100px;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  object-fit: cover;
}

.pic-on-add {
  height: 100% !important;
  width: 100% !important;
  border-radius: 100% !important;
}

.Cemara-te-img {
  width: 20px;
  height: 15px;
  position: absolute;
}

.add-kiya-muja {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50% !important;
}

.full-name-bug-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}

.i-t-a-host-itm {
  outline: none;
  width: 280px;
  height: 30px;
  border: none;
  margin-left: 1rem;
}

.SandIcon-addeting-div {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background: var(--Pink, #00ad5a);
  position: absolute;
  right: 1rem;
  bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
