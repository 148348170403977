.main_subcat_div {
  width: 100%;
  height: 100vh;
  margin-top: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.sub_div_of_subcat {
  width: 90%;
  height: 90vh;
  gap: 1.5rem;
  margin: 4rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.main_cat_name {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
  height: auto;
  /* background-color: yellowgreen; */
}
.main_cat_name > h1 {
  font-size: 35px;
  font-weight: 500;
  color: #2d2d2d;
  font-family: Be Vietnam Pro;
}
.subcategory_show_div {
  height: 90%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}
.subcategory_name_main_div {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
}
.sub_cat_title {
  width: 100%;
}
.sub_cat_title > h3 {
  font-size: 25px;
  font-weight: 500;
  color: #2d2d2d;
  font-family: Be Vietnam Pro;
}

.subcat_card_div {
  width: 250px;
  height: 80px;
  background-color: #ffffff00;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-align: center;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1rem;
  color: #00ad5a;
}
.subcat_card_div:hover {
  /* border: 1px solid #00ad5a; */
  transition: all 0.5s ease;
  background-color: #00ad5a;
  color: white;
}
.subcat_card_div > h4 {
  font-size: 15px;
  font-weight: 300;

  font-family: Be Vietnam Pro;
}
@media screen and (max-width: 1024px) {
  .main_subcat_div {
    height: fit-content;
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
  .subcategory_name_main_div {
    grid-template-columns: repeat(2, 1fr);
  }
  .sub_div_of_subcat {
    height: max-content;
    margin: 2rem 0rem;
  }
}
@media screen and (max-width: 600px) {
  .main_subcat_div {
    height: fit-content;
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
  .subcategory_name_main_div {
    grid-template-columns: repeat(1, 1fr);
  }
  .sub_div_of_subcat {
    height: max-content;
    margin: 2rem 0rem;
  }
}
