.Name-container-div {
  display: flex;
  align-items: center;
  position: relative;
}
.Address-main-popup-divv-ppx {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Address-main-payment-title {
  display: flex;
  justify-content: center;
}
.Address-section-divv-Pxx {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Close-btnn-modall-divv {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.text-span-div {
  padding-left: 15px;
}
.close-Address-main-span {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.Address-close-popup-divv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
}
.Address-main-span-divv {
  font-size: 25px;
  font-weight: 500;
}
.input-feild-Of-address {
  width: 311px;
  height: 45px;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  outline: none;
  padding-left: 20px;
  box-sizing: border-box;
}

.City-of-Addresss-main {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.Address-dropdown-city {
  width: 311px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.City-Adddress-divv-Dropdown {
  width: 280px;
  height: 40px;
  outline: none;
  border: none;
  background: transparent;
}

.work-req-here-input {
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  outline: none;
}

.Your-name-tagg-Here {
  font-weight: 500;
}

.Address-next-btnn-divv {
  padding-right: 14px;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.Add-Next-btnn {
  background: #00ad5a !important;
  border-radius: 6px !important;
  width: 311px !important;
  height: 45px !important;
  color: white !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  border: none !important;
  outline: none !important;
}
.form-errror {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.main_time_slot_div {
  width: 100%;
  height: 40vh !important;
  overflow-y: scroll;
  margin: 1rem 0rem;
}
.main_time_slot_div::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 360px) {
  .Address-next-btnn-divv {
    padding-right: 1px;
  }
}

@media screen and (max-width: 320px) {
  .Address-next-btnn-divv {
    padding-right: 1px;
  }
  .Address-dropdown-city {
    width: 270px;
  }
  .input-feild-Of-address {
    width: 270px;
  }
  .City-Adddress-divv-Dropdown {
    width: 250px;
  }
  .Add-Next-btnn {
    width: 270px !important;
  }
}
