.main_sec5_div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding: 3rem 2rem;
}

.sec5_sub_div {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.sec5_card {
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.sec5_text p {
  text-align: center;
  color: #706c74;
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.sec5_text h4 {
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sec5_text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.btn5 {
  width: 180px;
  height: 50px;
  /* padding: 13px 23px; */
  outline: none;
  border: none;
  background: #00ad5a;
  border-radius: 4px;
  color: #fff;
}
.btn5:hover {
  background-color: #fff;
  color: #00ad5a;
  border: 1px solid #00ad5a;
}

/* tablet style start here */
@media screen and (max-width: 1024px) {
  .sec5_sub_div {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
  }
  .sec5_card {
    width: 175px;
  }

  .sec5_text {
    gap: 0.5rem;
  }
  .sec5_text h4 {
    font-size: 15px;
  }
  .sec5_text p {
    font-size: 10px;
  }
}
/* mobile style start here */
@media screen and (max-width: 600px) {
  .sec5_sub_div {
    width: 100%;
    display: grid;
    place-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  .sec5_card {
    width: 150px;
  }

  .sec5_text h4 {
    font-size: 12 px;
  }
}
