.Modalbody {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.Modalbody > h4 {
  color: var(--Black, #14161b);
  font-family: Be Vietnam Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  text-transform: capitalize;
}
