.BookProject-Main-divv {
  padding-top: 5rem;
  height: max-content !important;
}
.Deals-heading-Divv {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100px;
  align-items: center;
  /* background-color: aqua; */
}
.Deals-section-main-divv {
  display: flex;
  justify-content: center;
}
.Spann-heading-of-divv {
  font-size: 22px;
  font-weight: 550;
}
.All-Boxes-in-One {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
}
@media screen and (max-width: 885px) {
  .All-Boxes-in-One {
    grid-template-columns: repeat(3, 1fr);
    padding: 20px;
  }
}
@media screen and (max-width: 768px) {
  .All-Boxes-in-One {
    gap: 1.5rem;
  }
}
@media screen and (max-width: 428px) {
  .All-Boxes-in-One {
    grid-template-columns: repeat(2, 1fr);
  }
  .All-Boxes-in-One {
    gap: 1rem;
  }
}
@media screen and (max-width: 428px) {
  .All-Boxes-in-One {
    grid-template-columns: repeat(1, 1fr);
  }
  .All-Boxes-in-One {
    gap: 1.5rem;
  }
}
