.forget-pass-sec-divv {
  display: flex;
}

.forgotPassMainModalDiv > div > .modal-content {
  border-radius: 20px !important;
}

.back-btnn-main-divv {
  display: flex;
  height: 100px;
  width: 120px;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.back-span-tagg-ppx {
  font-weight: 500;
  font-size: 20px;
}

.forget-email-input-div {
  display: flex;
  width: 311px;
  height: 45px;
  align-items: center;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  background-color: #fff;
}

.email-input-image-forget-div {
  width: 40px;
  display: flex;
  justify-content: center;
}
.forgot-input-feild {
  border: 1px solid #fff;
  outline: none;
  width: 90% !important;
  height: 90% !important;
  padding: 2px;
}
.forgot-button {
  width: 311px;
  height: 45px;
  border-radius: 6px;
  background: #00ad5a;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  border: none;
  outline: none;
  cursor: pointer;
}
.span-tagg-for-pass {
  font-size: 30px;
  font-weight: 600;
}

.forget-password-main-form {
  gap: 30px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.para-forget-divv-span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.para-forget-span-tagg {
  text-align: center;
  color: #6f778b;
  width: 80%;
}

@media screen and (max-width: 885px) {
  .imgg-black-man-divv {
    display: none;
  }
  .Forget-sec-main-divv {
    height: 400px;
  }
}

@media screen and (max-width: 428px) {
  .para-forget-divv-span {
    width: 90%;
  }
  .back-btnn-main-divv {
    display: flex;
    height: 60px !important;
    width: 120px;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 360px) {
  .Forget-sec-main-divv {
    height: 420px;
  }
}
