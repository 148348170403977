.notification {
  position: relative;
  display: inline-block;
  border-radius: 2px;
}

.notification .badge {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #00ad5a;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile_div {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 1rem;
}

.profile_pic_box {
  width: 37px;
  height: 37px;
  border-radius: 37px;
  background-color: #00ad5a;
  overflow: hidden;
}
.profile_pic_box > img {
  width: 100%;
  aspect-ratio: 3/3;
  object-fit: cover;
}
/*  dropdwon style start here */
.drop_tittle {
  background-color: transparent !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 1rem !important;
}
.dropdownmenu {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 0.3rem !important;
  flex-direction: column !important;
}
.drop_itme {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  gap: 1rem !important;
}
.drop_itme:hover {
  background-color: #1a59f3 !important;
}
.drop_itme:hover > #profileico > g > path {
  fill: #fff !important;
}

.line_div {
  width: 95%;
  height: 2px;
  background-color: #e2e2e2;
}

.dropdown-menu-dark {
  background-color: #ffffff !important;
  --bs-dropdown-link-color: #000000 !important;
}
.drop_tittle {
  color: #ffffff !important;
}

.Srch-noti-guy-divv {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}
.Noti-guy-Srch-divv {
  display: none;
}

.nav-component-divv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

@media screen and (max-width: 1040px) {
  .Srch-noti-guy-divv {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 885px) {
  .Noti-guy-Srch-divv {
    display: flex;
    align-items: center;
    gap: 2.5rem;
  }
  .Srch-noti-guy-divv {
    display: none;
  }

  .menu_text_div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}
@media screen and (max-width: 428px) {
  .Noti-guy-Srch-divv {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .nav-component-divv {
    gap: 1.5rem;
  }
  .mobile_main_menu_box {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .menu_text_div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .Srch-noti-guy-divv {
    display: block;
    display: flex;
  }

  .searchh {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  .Noti-guy-Srch-divv {
    display: flex;
    align-items: center;
    gap: 0.7rem;
  }
  .nav-component-divv {
    gap: 0.8rem;
  }
}

@media screen and (max-width: 320px) {
  .Noti-guy-Srch-divv {
    display: flex;
    align-items: center;
    gap: 0.7rem;
  }
  .nav-component-divv {
    gap: 0.5rem;
  }
}
