/* .svg-p-div {
  background: #ffffff;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  border-radius: 4px;
  width: 300px;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding: 0.5rem;
} */

.errror-ka-liya {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

/* .i-t-a {
  outline: none;
  width: 280px;
  height: 30px;
  border: none;
} */

.Register-btn {
  color: white;
  background: #00ad5a;
  border-radius: 6px;
  width: 300px;
  height: 45px;
  outline: none;
  border: none;
}

.Submi-btnn-pxx-cg {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 360px) {
  .errror-ka-liya {
    width: 99%;
  }
}
