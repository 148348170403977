.sec7 {
  padding-top: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.main_swiper_div_box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.sec7_swiper_main_box {
  width: 100% !important;
  height: 300px !important;
  display: grid;
  grid-template-rows: 50% 50%;
}
.div_of_img_sec7 {
  width: 100%;
  height: 100% !important;

  display: flex;
  align-items: center;
  justify-content: center;
}
.div_of_img_sec7 > .img_sec7 {
  border-radius: 50%;
  width: 70%;
  aspect-ratio: 3/3;
  object-fit: cover;
}
.sec7_text_box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 10px;
}
.sec7_text_box > h6 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

/* tablet style start here */
@media screen and (max-width: 1024px) {
  .div_of_img_sec7 > img {
    border-radius: 50%;
    width: 50%;
    aspect-ratio: 3/3;
    object-fit: cover;
  }
}
