.main_header {
  background: url(../../../../User/assets/header.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  margin-top: 5rem;
  height: calc(100vh - 5rem);
  background-size: 100% 100%;
  display: grid;
  place-items: center;
}
header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.header_text h4 {
  color: #fff;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 15.6px;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.header_text h1 {
  color: #fff !important;
  text-align: center !important;
  font-family: Be Vietnam Pro !important;
  font-size: 90px;
  font-style: normal !important;
  font-weight: 800 !important;
}
.h_text {
  -webkit-text-stroke: 1px white;
  -webkit-text-fill-color: transparent;
}
.main_card_box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.card {
  /* display: grid !important;
  place-items: center !important; */
  background-color: #fff;
  border-radius: 6px;
  width: 163px !important;
  height: 163px !important;
  display: grid !important;
  grid-template-rows: 60% 40% !important;
  flex-shrink: 0;
}
.active.card {
  background: #00ad5a !important;
  color: #fff;
}
.card h5 {
  color: #14161b;
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
/* HIDE NAV MWNU TABLET AND MOBILE  */
@media screen and (max-width: 1024px) {
  header {
    width: 100%;
  }
  .card {
    width: 140px !important;
    height: 140px !important;
  }
  .main_header {
    margin-top: 5rem;
    height: calc(50vh - 5rem);
    background-size: 100% 100%;
  }
}
@media screen and (max-width: 600px) {
  .main_header {
    margin-top: 5rem;
    height: calc(100vh - 5rem);
    background-size: 200% 100%;
  }
  .main_card_box {
    flex-wrap: wrap;
  }
  .header_text h4 {
    font-size: 12px;
    letter-spacing: 15.6px;
  }
  .header_text h1 {
    font-size: 45px !important;
  }
  .card {
    width: 120px !important;
    height: 120px !important;
  }
}
