.main_div {
  width: 100%;
  height: 100%;
}
.sub_main_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  padding: 15rem 7rem;
}
._img__box {
  width: 194px;
  height: 194px;
}
.text > h2 {
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
}
.text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.text > p {
  width: 290px;
  height: auto;
  flex-shrink: 0;
  color: var(--grey, #706c74);
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.sbtn_1 {
  width: 120px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #00ad5a;
  border: none;
  outline: none;
  color: #fff;
}
