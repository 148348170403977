.fastar-mnb {
  height: 40px;
  width: 40px;
}

.Rate-user-name {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.model-contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.Review-span-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.Write-a-text {
  background: #ffffff;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  outline: none;
  padding: 1rem;
}
.jacob-spann-diivv {
  display: flex;
  justify-content: center;
}

.jacob-spann-tagg {
  font-weight: 500;
  font-size: 25px;
}
.textatea-span {
  width: 94%;
  font-weight: 500;
  font-size: 14px;
  color: #1e2d38;
  margin-bottom: 5px;
}

.Advocate-rate-span {
  color: #1e2d38;
  font-weight: 700;
  font-size: 22px;
}

.Submit-btn {
  background: #00ad5a;
  border-radius: 6px;
  color: white;
  width: 265px;
  height: 45px;
  border: none;
  outline: none;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 1rem;
}
.rating_profile_img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;
}
.rating_profile_img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 320px) {
  .Write-a-text {
    width: 271px;
  }
}
