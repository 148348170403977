/* GENERAL CONTAINER CLASS */

body {
  font-family: "Be Vietnam Pro", sans-serif;
  overflow-x: hidden;
} /* GENERAL SECTION STYLE */

/* .example::-webkit-scrollbar {
  display: none;
} */
/* GENERAL HRADING STYLE */
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
  color: black;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.6rem;
}
h4 {
  font-size: 1.1rem;
}
h5 {
  font-size: 0.95rem;
}
h6 {
  font-size: 0.8rem;
}
