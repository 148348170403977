/* .container {
  width: 70%;
  margin-inline: auto;
  max-width: 1920px;
  background-color: aqua;
} */
/* GENERAL SECTION STYLE */
/* section {
 
  background: rgb(255, 7, 7);
  transition: all 500ms ease;
} */
.sec2 {
  padding: 5rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background: #e7e7e7; */
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background: #00ad5a; */
}
.main_swiper_div {
  padding: 3rem 0rem 0rem 0rem;
  width: 100%;
}
.swiper_loading_card {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
/* .swiper-slide {
  width: 300px !important;
  height: 320px !important;
  margin-right: 0px;
} */

.swiper_main_box {
  width: 100% !important;
  height: 300px !important;
  display: grid;
  grid-template-rows: 60% 40%;
  gap: 1rem;
  border: 1px solid #e7e7e7;
  border-radius: 6px;
  background: #ffffff;
  overflow: hidden;
  cursor: pointer;
}
.swiper_main_box:hover {
  /* transform: translateY(3px); */
  border: 1px solid #00ad5a;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.div_of_img_sec2 {
  overflow: hidden;
}
.div_of_img_sec2 > img {
  width: 80%;
  aspect-ratio: 3/2;
  object-fit: cover;
}
.hheading__sec2 {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.swiper_btn {
  border: 1px solid #e7e7e7;
  background: transparent;
  border-radius: 50% 50%;
}

.swiper_btn:hover .mySvgPath {
  fill: white;
}

.swiper_btn:hover {
  background-color: #00ad5a;
  color: white;
}
.div_bttn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0 3rem;
}

.text_box {
  /* background-color: aqua;
  width: 100%; */
  padding: 1rem;
}
.price {
  color: #00ad5a;
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.p__text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4rem;
}

p {
  margin-bottom: 0rem !important;
}
@media screen and (max-width: 1024px) {
  .hheading__sec2 {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (max-width: 768px) {
  /* Adjust the breakpoint as needed */
  .box-2,
  .box-3 {
    display: none;
  }
  .swiper_main_box {
    width: 100% !important;
    height: 240px !important;
    display: grid;
    grid-template-rows: 50% 50%;
    gap: 1rem;
    border: 1px solid #e7e7e7;
    border-radius: 6px;
    background: #ffffff;
    overflow: hidden;
    cursor: pointer;
  }
}
