.Select-div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.model-book {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Book-Now-text {
  background: #00ad5a;
  border-radius: 6px;
  width: 340px;
  height: 45px;
  color: white;
  border: none;
  outline: none;
}
.Span-divv-select-pay {
  width: 68%;
}
.Select-time-span {
  width: 100%;
  color: #14161b;
  font-weight: 500;
  font-size: 20px;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #00ad5a !important;
  border-radius: 30px !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range :hover {
  border-radius: 30px !important;
}
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  width: 2rem !important;
  height: 2rem !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  line-height: 2rem !important;
}
.react-datepicker {
  border: none !important;
}

.react-datepicker__navigation {
  top: 12px !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: black;
}
.react-datepicker__day-names {
  display: flex !important;
  justify-content: center;
  gap: 5px !important;
  width: 100% !important;
}
.react-datepicker__header {
  border: none !important;
  background-color: white !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 10px !important;
}
.Date_MAin_divvv {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  width: 100%; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}
@media screen and (max-width: 428px) {
  .Span-divv-select-pay {
    width: 83%;
  }
}
@media screen and (max-width: 390px) {
  .Span-divv-select-pay {
    width: 90%;
  }
}
@media screen and (max-width: 376px) {
  .time-apoy-mant {
    width: 146px;
    font-size: 12px;
  }
  .Select-time-span {
    width: 87%;
  }
  .Book-Now-text {
    width: 311px;
  }
  .Span-divv-select-pay {
    width: 76%;
  }
}

@media screen and (max-width: 360px) {
  .Span-divv-select-pay {
    width: 85%;
  }
  .time-apoy-mant {
    width: 130px;
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .time-apoy-mant {
    width: 130px;
    font-size: 12px;
  }
  .Book-Now-text {
    width: 279px;
  }
}
