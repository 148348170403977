.main_notification_div {
  width: 100%;
  height: 100%;
}
.Noti_heading > span {
  color: var(--Black, #14161b);
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}
.main_notification_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
}
.notification_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.sub_Notifi_box {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  padding: 1rem 0rem;
}
.Notification_img_box {
  width: 64px;
  height: 64px;
  flex-shrink: 0;

  border-radius: 64px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Notification_img_box > img {
  width: 100%;
  aspect-ratio: 3/3;
  object-fit: cover;
}
.Notification_text_div {
  display: flex;
  /* flex-direction: column; */
  /* align-items: flex-start; */
  /* justify-content: center; */
  gap: 1rem;
}

.Noti_msg > h1 {
  color: var(--Black, #14161b);

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Noti_msg > span {
  color: var(--Grey, #706c74);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dates_div_inf0 {
  padding-bottom: 0.5rem;
}

.dates_div_inf0 > span {
  color: var(--Grey, #706c74);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.bottom_line_box {
  width: 100%;
  height: 2px;
  background-color: #e2e2e2;
}

.Read_btn {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 10px;
  color: #1a59f3;
}

.cls_btn {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 10px;
  color: #1a59f3;
}

@media screen and (min-width: 1025px) {
  .notification-box.expanded {
    display: none;
  }
}
