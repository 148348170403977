.main_pay_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
}
.sub_pay_main_div {
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paymnet_card {
  width: 30%;
  height: max-content;
  padding-bottom: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
}
.div_pay_hed {
  width: 100%;
  background-color: #00ad5a;
  padding: 10px;
  text-align: center;
}
.div_pay_hed > h2 {
  font-family: Be Vietnam Pro;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
}
.div1_pay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.pay_inner_div {
  width: 95%;
  display: grid;
  grid-template-columns: 30% 65%;
  /* border: 1px solid #9a9999; */
  padding: 10px 10px;
  border-radius: 8px;
}
.div_of_img_pay {
  width: 85px;
  height: 85px;
  overflow: hidden;
  border-radius: 50%;
}
.div_of_img_pay > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pay_details {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.pay_details > h2 {
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-weight: 500;
}
.pay_details > p {
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-weight: 400;
}

.cat_p {
  font-family: Be Vietnam Pro;
  font-size: 12px !important;
  font-weight: 400;
  color: #616161;
}
.reating_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.reating_div > p {
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-weight: 400;
}

.pytn_1 {
  width: 120px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #00ad5a;
  border: none;
  outline: none;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pytn_1:hover {
  color: #fff;
}

@media screen and (max-width: 1050px) {
  .paymnet_card {
    width: 50%;
    height: max-content;
    padding-bottom: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .paymnet_card {
    height: max-content;
    width: 100%;
    padding-bottom: 1rem;
  }
}
/* @media screen and (max-width: 415px) {
  .paymnet_card {
    height: 60%;
    width: 100%;
  }
}
@media screen and (max-width: 376px) {
  .paymnet_card {
    height: 70%;
    width: 100%;
  }
} */
