.RegisterTwo-main-sec {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
}

.RegisterTwo-main-div {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.whit-line-tyrnspar {
  background: #d9d9d9;
  border-radius: 30px;
  width: 240px;
  height: 4px;
  display: flex;
}

.doler-ka-liya {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  height: 45px;
  width: 345px;
  padding: 1rem;
  outline: none;
}

.ya-bot-input {
  background: #ffffff;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  height: 39px;
  width: 345px;
  border: none;
  outline: none;
}

.doler-span {
  font-weight: 400;
  font-size: 15px;

  color: #919ba2;
}

.Write-here-mahu {
  background: #ffffff;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  outline: none;
  padding: 1rem;
  width: 345px;
}

.Lorem-btn {
  display: flex;
  align-items: center;

  gap: 1rem;
}

.Lorem-ispun-btn-one {
  display: flex;
  align-items: center;
  background: #ececec;
  border-radius: 4px;
  border: none;
  font-weight: 500;
  font-size: 13px;
  height: 30px;
  width: max-content;
}

.hghu {
  height: 100px;
}

.selact-tag-one {
  background: #ffffff;
  border: 1px solid #dbdde2;
  border: none;
  outline: none;
  width: 330px;
}

.selact-ka-liya-jugad {
  background: #ffffff;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  height: 45px;
  padding: 0.6rem;
  width: 350px;
}
.pro-button-one {
  width: 600px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  color: #b4b1b6;
  border: none;
}

.pro-button-two {
  width: 600px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #ddfdee;
  color: #00ad5a;
  border: none;
}
/* .pro-main-btn-divv {
    width: 50%;
  } */
.per-divv-span-one {
  color: #fff;
  width: 40px;
  height: 40px;
  background-color: #b4b1b6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
}
.per-divv-span-two {
  color: #fff;
  width: 40px;
  height: 40px;
  background-color: #00ad5a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
}
.taop-tap {
  background: #f7fbff;
  object-fit: cover;
  height: 180px;
  border: 0.5px solid lightgray;
  border-style: dashed;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.document-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Default-to-imgg {
  width: 62px;
  height: 45px;
}

.add-upolod-imgs {
  width: 22px;
  height: 18px;
}

.add-img-ho {
  width: 80%;
  display: flex;
  justify-content: flex-end;
}

.Tap-to-imgg {
  width: 300px;
  object-fit: cover;
  height: 180px;
  border: 0.5px solid lightgray;
  border-style: dashed;
  border-radius: 8px;
}

.Certificate-span {
  color: #00ad5a;
  font-weight: 500;
  font-size: 15px;
}

.cponform-regiestar {
  width: 343px;
  background: #00ad5a;
  border-radius: 6px;
  height: 45px;
  outline: none;
  border: none;
  color: white;
  font-weight: 700;
  font-size: 15px;
}
.skill-item {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: max-content;
  padding: 5px 5px 5px 7px;
  background-color: #ececec;
  border-radius: 4px;
}

.Skills-main-divv-ppx {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  width: 342px;
}
.add-skill-input-field {
  border: none;
  outline: none;
}
.add-skill-input {
  display: flex;
}
.hathi-bhai {
  display: flex;
  flex-direction: column;
}
.add-skill-btn {
  display: none !important;
}
.add-delet-option {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
  /* overflow-x: scroll;  */
}
.sab-img-ka-div {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}

.add-kiya-huva-pic {
  width: 79.71px;
  height: 79.71px;
  border-radius: 4.83099px;
  object-fit: cover;
}

.Btn-dada-delet {
  position: absolute;
  right: -7px;
  border: none;
  border-radius: 40px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #8a8a8a;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
}
.Ht-ml-for {
  background: #fdfaff;
  /* margin-top: 1rem;  */
  border: 1px solid #00ad5a;
  border-radius: 4.83099px;
  width: 79.71px;
  height: 79.71px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Malte-pal-cls {
  display: none;
}
.overflowing-add-immgg {
  display: flex;
  gap: 20px;
  /* width: 100%; */
  /* background-color: #00ad5a; */
}
.Input_all_here_in_one {
  display: flex;
  justify-content: center;
}
/* .overflowing-add-immgg::-webkit-scrollbar {
  display: none;
} */
@media screen and (max-width: 1280px) {
  .whit-line-tyrnspar {
    width: 200px;
  }
  .pro-button-two {
    width: 515px;
  }
  .pro-button-one {
    width: 515px;
  }
}

@media screen and (max-width: 913px) {
  .whit-line-tyrnspar {
    width: 130px;
  }
}

@media screen and (max-width: 885px) {
  .whit-line-tyrnspar {
    width: 110px;
  }

  .pro-button-two {
    width: 360px;
  }
  .pro-button-one {
    width: 360px;
  }
  .selact-ka-liya-jugad {
    width: 345px;
  }
  .selact-tag-one {
    width: 321px;
  }
  .Professional-Info-child-div {
    width: 247px;
  }
}
@media screen and (max-width: 834px) {
  .pro-button-two {
    width: 340px;
  }
  .pro-button-one {
    width: 340px;
  }
}
@media screen and (max-width: 820px) {
  .pro-button-two {
    width: 335px;
  }
  .pro-button-one {
    width: 335px;
  }
}
@media screen and (max-width: 800px) {
  .pro-button-two {
    width: 320px;
  }
  .pro-button-one {
    width: 320px;
  }
}

@media screen and (max-width: 768px) {
  .pro-button-two {
    width: 310px;
  }
  .pro-button-one {
    width: 310px;
  }
}
@media screen and (max-width: 570px) {
  .whit-line-tyrnspar {
    width: 70px;
  }
}

@media screen and (max-width: 428px) {
  .whit-line-tyrnspar {
    width: 45px;
  }
  .pro-button-two {
    width: 170px;
    gap: 4px;
  }
  .pro-button-one {
    width: 170px;
  }
  .Ht-ml-for {
    width: 80px;
  }
  .overflowing-add-immgg {
    width: 200px;
    gap: 8px;
  }
  .per-divv-span-two {
    width: 35px;
    height: 35px;
  }
  .per-divv-span-one {
    width: 35px;
    height: 35px;
  }
  .RegisterTwo-main-div {
    width: 100%;
  }
  .input-from-r {
    width: 90%;
    /* padding-left: 1.5rem;  */
  }
}
@media screen and (max-width: 393px) {
  .pro-button-one {
    width: 145px;
  }
  .pro-button-two {
    width: 158px;
  }
  .input-from-r {
    width: 93%;
    padding-left: 1px;
  }
}
@media screen and (max-width: 376px) {
  .whit-line-tyrnspar {
    width: 45px;
  }

  .two-dada-span {
    width: 55px;
    height: 55px;
  }

  .one-dada-span {
    width: 55px;
    height: 55px;
  }
  .RegisterTwo-main-div {
    width: 100%;
  }
  .doler-ka-liya {
    width: 315px;
  }
  .selact-ka-liya-jugad {
    width: 315px;
  }
  .selact-tag-one {
    width: 295px;
  }
  .pro-button-two {
    width: 168px;
    gap: 12px;
  }
}

@media screen and (max-width: 440px) {
  .pro-button-two {
    width: 162px;
  }
  .doler-ka-liya {
    width: 303px;
  }
  .selact-ka-liya-jugad {
    width: 100%;
  }
  .selact-tag-one {
    width: 285px;
  }
  .cponform-regiestar {
    width: 310px;
  }
  .doler-hai-bhaiii,
  .kathryn-input22,
  .kathryn-sec-input,
  .selact-tag-one {
    width: 100% !important;
  }
  .add-delet-option {
    flex-direction: column;
  }
  .overflowing-add-immgg {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 320px) {
  .pro-button-two {
    width: 145px;
  }
  .doler-ka-liya {
    width: 275px;
  }
  .selact-ka-liya-jugad {
    width: 275px;
  }
  .cponform-regiestar {
    width: 270px;
  }
  .selact-tag-one {
    width: 250px;
  }
}

/* .overflowing-add-immgg {
  overflow-x: scroll;
} */
