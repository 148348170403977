.Chat-Mainn-Divv {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #ebebeb;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 4rem;
}
.Chat_screen_main_divv {
  width: 100%;
  display: flex;
  /* margin-top: 1rem; */
}
.imgg-Chatter-and-Time-divv {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin-left: 10px;
}

.Chatter-and-Imgg-divv-ppx {
  display: flex;
  align-items: center;
  gap: 15px;
}

.Under-saathme-Img-lorem {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.Kriti-span-tagg {
  font-size: 20px;
  font-weight: 700;
}

.Lorem-span-tagg {
  color: #706c74;
}

.chat-number-divv-Chatting {
  width: 20px;
  height: 20px;
  background-color: #00ad5a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 25px;
}

.Chat-N0-Main-Divv {
  width: 150px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Time-divv-Chatting {
  display: flex;
  justify-content: flex-end;
}
.Kriti-and-lorem-divv-Main {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 885px) {
  .Chat-N0-Main-Divv {
    width: 120px;
  }
}

@media screen and (max-width: 835px) {
  .Chat-N0-Main-Divv {
    width: 90px;
  }
}

@media screen and (max-width: 820px) {
  .Chat-N0-Main-Divv {
    width: 80px;
  }
}
@media screen and (max-width: 800px) {
  .Chat-N0-Main-Divv {
    width: 60px;
  }
  .Chat-Mainn-Divv {
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 428px) {
  .Chat-Mainn-Divv {
    padding: 1rem 1rem;
  }
  .Kriti-span-tagg {
    font-size: 14px;
  }
  .Lorem-main-span-divv {
    font-size: 11px;
  }
  .Imgg-off-kriti {
    width: 45px;
  }
}
@media screen and (max-width: 414px) {
  .Chatter-and-Imgg-divv-ppx {
    display: flex;
    gap: 9px;
  }
}

@media screen and (max-width: 390px) {
  .Kriti-and-lorem-divv-Main {
    gap: 4px;
  }
  .Lorem-span-tagg {
    font-size: 11px;
  }
}

@media screen and (max-width: 376px) {
  .Imgg-off-kriti {
    width: 41px;
  }
  .Kriti-span-tagg {
    font-size: 12px;
  }
}

@media screen and (max-width: 360px) {
  .Imgg-off-kriti {
    width: 33px;
  }
  .Chat-N0-Main-Divv {
    width: 50px;
  }
}
