.main_div_sidebar {
  background-color: transparent;
}
.submain_div {
  width: 270px;
  height: auto;
  border-radius: 6px;
  border: 0.5px solid #ededed;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
  padding: 2rem 0rem;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.profilepic_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.img_box_profile_pic {
  width: 132px;
  height: 132px;
  flex-shrink: 0;
  border-radius: 132px;

  overflow: hidden;
}
.img_box_profile_pic > img {
  width: 100%;
  aspect-ratio: 3/3;
  object-fit: cover;
}

.profile_title_div > h1 {
  color: var(--Black, #14161b);
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.mainnav {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.SideBarTxt:hover {
  color: #00ad5a !important;
}
.SideBarTxt {
  color: #14161b;
  font-family: Be Vietnam Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.line_div_side {
  position: relative;
}
.inerline_box {
  position: absolute;
  left: 45px;
  width: 180px;
  height: 2px;
  background-color: #ededed;
}
.SideBarIcon {
  padding: 8px;
  border-radius: 100%;
}

.SideBarIconActive {
  background-color: black !important;
}

@media screen and (max-width: 1024px) {
  .submain_div {
    width: 270px;
  }

  .img_box_profile_pic {
    width: 50px;
    height: auto;
    flex-shrink: 0;
    border-radius: 132px;
    overflow: hidden;
  }
  .profile_title_div > h1 {
    font-size: 11px;
  }
}
@media screen and (max-width: 600px) {
  .submain_div {
    width: 70px;
  }
  .SideBarTxt {
    display: none;
  }
  .inerline_box {
    display: none;
  }
}
