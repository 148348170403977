.main_view_section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  width: 100%;
}
.view_details_box {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.profile_card {
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}
.profile_pic_div {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.profile_img_box {
  width: 57px;
  height: 57px;
  flex-shrink: 0;
  border-radius: 58.462px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-self: center;
}
.profile_img_box > img {
  width: 100%;
  aspect-ratio: 3/3;
  object-fit: cover;
}
.info_section {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem 0rem;
  gap: 1rem;
}
.nameee_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nameee_box > h1 {
  color: var(--Black, #14161b);
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.dateandtime {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
}
.datediv > h6,
.timediv > h6 {
  color: var(--Grey, #706c74);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.datediv > h4,
.timediv > h4 {
  color: var(--Black, #14161b);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Addressdiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.Addressdiv > h6 {
  color: var(--Grey, #706c74);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Addressdiv > h4 {
  color: var(--Black, #14161b);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.MobileNumber_section {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.MobileNumberdiv {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.MobileNumberdiv > h6 {
  color: var(--Grey, #706c74);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.MobileNumberdiv > h4 {
  color: var(--Black, #14161b);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.pricediv > h4 {
  color: var(--Green, #00ad5a);
  text-align: right;
  font-family: Be Vietnam Pro;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px;
}
.text_section {
  width: 90%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
}

.text_section > h2 {
  color: var(--Black, #14161b);
  font-family: Be Vietnam Pro;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
}
.text_section > p {
  color: var(--Grey, #706c74);
  font-family: Be Vietnam Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
@media screen and (max-width: 600px) {
  .view_details_box {
    width: 100%;
  }
  .profile_card,
  .text_section {
    width: 100%;
  }
}
