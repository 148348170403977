.mybooking-main-section {
  width: max-content;
  height: 100%;
  border-radius: 6px;
  background: #fff;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 4rem;
}

.mybooking-main-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  padding: 2rem;
}

.mybooking-single11-div {
  width: 68%;
}

.Spann-doller-fixx-divv-ppx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.span-exp-divv {
  /* width: 105px; */
  height: max-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.My-Bookings-span {
  font-size: 27px;
  font-weight: 700;
  color: #1e2d38;
}
.Caepenetr-imgg-main {
  width: 100%;
  height: 109px;
  background: #ffffff;
  border-radius: 6px;
  justify-content: center;
  margin-bottom: 1rem;
}
.Upcoming-btn-Completed {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Upcoming-button {
  width: 50%;
  height: 45px;
  border-radius: 6px 0 0 6px;
  border: none;
  outline: none;
  background-color: transparent;
  color: #000000;
  font-weight: 500;
}

.completed-button {
  width: 50%;
  height: 45px;
  border-radius: 0 6px 6px 0;
  border: none;
  outline: none;
  background-color: #fff;
  font-weight: 500;
  color: black;
}

.Underline-Min-Divv {
  width: 100%;
  border: 1px solid #ece7fa;
}
.main-div-of-card23 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.single-card-div44 {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 1rem;
  border: 1px solid #e5eff5;
  border-radius: 12px;
}

.inner-div-card-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.name-span-text-div {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.starimage-div {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.image-review12-span {
  font-size: 12px;
  font-weight: 500;
  color: #1e2d38;
}

.per-hour44-span {
  font-size: 13px;
  font-weight: 500;
  color: #6f778b;
}

.date12-span {
  font-size: 13px;
  font-weight: 400;
  color: #6f778b;
}

.jun5-span {
  font-size: 15px;
  font-weight: 600;
  color: #1e2d38;
}

.date-time-main-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
}

.time-dchild-div {
  display: flex;
  flex-direction: column;
}

.meeting-button {
  width: 380px;
  height: 35px;
  background-color: #e0f2ff;
  border-radius: 6px;
  border: none;
  outline: none;
  color: 13px;
  font-weight: 700;
  color: #006ebd;
}

.ma-coduy-hu {
  display: flex;
  gap: 1rem;
}

.chat-logo-btn {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-left: 2.5rem;
}

.msg-svg-1 {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
}

.upcoming-button-19 {
  width: 75px;
  height: 21px;
  color: #ffffff;
  background-color: #006ebd;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  border: none;
  outline: none;
}

.button-time-div {
  margin-top: 0.5rem;
}

.single-card-div44:hover {
  border: 1px solid #00ad5a;
}

.jenny-img-img {
  width: 78px;
  height: 78px;
}

.star-img-img {
  width: 70px;
  height: 14px;
}

.name-span13 {
  font-weight: 700;
  font-size: 18px;
}

.dolar-span-span1 {
  font-weight: 600;
  font-size: 16px;
  color: #006ebd;
}

.Upcoming-btn-Completed {
  display: flex;
}

.imgg-text-and-upcoming-divv-ppx {
  display: flex;
  justify-content: space-between;
}
.all-immg-car-plu-main {
  display: flex;
  width: 208px;
  height: 80px;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.span-blue-upcoming-tagg-ppx {
  font-size: 12px;
  font-weight: 500;
  /* color: #1632c6; */
}

.Upcoming-wandh-set-divv {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upcoming-blue-main-divv {
  background-color: #1632c6;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  border-radius: 4px;
}
.upcoming-card {
  background-color: #1632c6;
  color: #ffffff;
  /* Other styles */
}
.rejected-card {
  background-color: #ff0000;
  color: #ffffff;
}

.accepted-card {
  background-color: #ffea00;
  color: #000000 !important;
}
.Rated_div_btn {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  color: #706c74;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.Rated_div_btn > span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #000000;
}
/* .div-to-make-card-responsive-upcoming {
  width: 100%;
  height: 80%;
  position: relative;
}
#pagination {
  position: absolute;
  bottom: 0;
  right: 0;
} */

@media screen and (max-width: 885px) {
  .mybooking-main-section {
    width: 100%;
    padding: 2rem;
  }
  .mybooking-main-div {
    width: 100%;
    padding: 0rem;
  }
  .date-time-main-div {
    width: 54%;
  }
  .Upcoming-button {
    width: 50%;
  }

  .completed-button {
    width: 50%;
  }
  .main-div-of-card23 {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 835px) {
  .single-card-div44 {
    width: 453px;
  }

  .meeting-button {
    width: 350px;
  }
  .main-div-of-card23 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 800px) {
  .Upcoming-button {
    width: 40%;
  }

  .completed-button {
    width: 40%;
  }

  .single-card-div44 {
    width: 430px;
  }

  .meeting-button {
    width: 330px;
  }

  .chat-logo-btn {
    padding-left: 1.5rem;
  }
  .Upcoming-Completed-Mainn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .Underline-Min-Divv {
    width: 80%;
  }
  .date-time-main-div {
    width: 64%;
  }
}

@media screen and (max-width: 768px) {
  .single-card-div44 {
    width: 400px;
  }
}
@media screen and (max-width: 430px) {
  .mybooking-main-section {
    width: 100%;
    padding: 2rem 1rem;
  }

  .date-time-main-div {
    width: 94%;
  }
  .jun5-span {
    font-size: 14px;
  }
  .main-div-of-card23 {
    width: 90%;
    gap: 1rem;
    grid-template-columns: repeat(1, 1fr);
  }
  .Upcoming-button {
    width: 40%;
  }

  .completed-button {
    width: 40%;
  }

  .single-card-div44 {
    width: 100%;
  }

  .meeting-button {
    width: 360px;
  }

  .chat-logo-btn {
    padding-left: 1.5rem;
  }

  .mybooking-single11-div {
    text-align: center;
  }

  .mybooking-main-div {
    width: 100%;
    margin-bottom: 2rem;
  }
  .div-to-make-card-responsive-upcoming {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
  }

  .ingg-broklyn-yellocap-divv {
    width: 45px;
    height: 45px;
    overflow: hidden;
    background-color: #00ad5a;
    border-radius: 50%;
  }
  .ingg-broklyn-yellocap-divv > img {
    width: 100%;
    height: auto;
    aspect-ratio: 3/3;
    object-fit: cover;
  }
  .single-card-div44 {
    padding: 1rem;
  }
  .all-immg-car-plu-main {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 5px;
  }
  .span-exp-divv {
    justify-content: flex-start;
  }
  .brok-spann-tagg-ppyy {
    font-size: 12px;
  }
  .rating-and-star-spann {
    font-size: 12px;
  }
  .Caepenetr-imgg-main {
    height: 100%;
  }
  .broklynn-star-main-divv-uux {
    margin: 1rem 0rem;
  }
  .imgg-text-and-upcoming-divv-ppx {
    flex-direction: column-reverse;
  }
  .Upcoming-wandh-set-divv,
  .Upcoming-wandh-set-divv {
    justify-content: end !important;
  }
  .Spann-doller-fixx-divv-ppx {
    flex-direction: column-reverse;
  }
  /* .div-to-make-card-responsive-upcoming {
    width: 100%;
    height: 100%;
    position: relative;
  }
  #pagination {
    position: absolute;
    bottom: 0;
    right: 0;
  } */
}

@media screen and (max-width: 415px) {
  .date-time-main-div {
    width: 100%;
  }
}
@media screen and (max-width: 391px) {
  .single-card-div44 {
    width: 100%;
  }

  .chat-logo-btn {
    padding-left: 0.5rem;
  }

  .meeting-button {
    width: 315px;
  }
  .jun5-span {
    font-size: 12px;
  }
}

@media screen and (max-width: 360px) {
  .single-card-div44 {
    width: 216px;
  }

  .chat-logo-btn {
    padding-left: 0rem;
  }

  .meeting-button {
    width: 300px;
  }

  .Upcoming-button {
    width: 185px;
  }

  .completed-button {
    width: 166px;
  }
  .Underline-Min-Divv {
    width: 98%;
  }
}

@media screen and (max-width: 320px) {
  .single-card-div44 {
    padding: 5px;
    width: 184px;
  }

  .jun5-span {
    font-size: 10px;
  }

  .meeting-button {
    width: 270px;
  }

  .Upcoming-button-19 {
    font-size: 12px;
    width: 70px;
  }

  .ma-coduy-hu {
    gap: 0.5rem;
  }

  .Upcoming-button {
    width: 160px;
  }

  .completed-button {
    width: 152px;
  }

  .date-time-main-div {
    width: 90%;
  }
}
