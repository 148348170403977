.main_footer {
  width: 100%;
  height: 459px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
footer {
  width: 80vw;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  border-bottom: 1px solid #a6a9b1;
}
.footer1 {
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
}
.footer1 p {
  color: #a6a9b1;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  font-family: "Be Vietnam Pro", sans-serif;
}
.email_sub {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row !important;
  gap: 1rem;
}
.email_input {
  width: 235px;
  height: 45px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 4px;
  overflow: hidden;
  padding: 10px;
}
.email_input > input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
}
.footer2 {
  display: grid;
  padding: 1rem;
}
.footer2 h4 {
  padding: 0 1rem;
  font-family: "Be Vietnam Pro", sans-serif;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.last_div {
  width: 80%;
  height: 10vh;
  /* background-color: burlywood; */
}
.main_menu {
  display: grid;
  grid-template-columns: 40% 60%;
  padding: 1rem;
}
.footer_menu {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

a {
  text-decoration: none !important;
}
.span_link {
  text-decoration: none;
  color: #a6a9b1;
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 235.714% */
}
.last_btn {
  color: var(--green, #00ad5a);
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}
.footer3 {
  display: grid;
  grid-template-rows: 10% 80%;
  padding: 1rem;
}
.footer3 > h4 {
  padding: 0 1rem;
  font-family: "Be Vietnam Pro", sans-serif;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.main_contact {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
}
.location,
.phoneNumber,
.email {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.8rem;
}
.location P,
.f_link {
  color: #a6a9b1;
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.last_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.L_text p {
  color: #a6a9b1;
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.social_ico {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.icon_box {
  background-color: #2a2d33;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .footer3_mobo {
  display: none;
} */
@media screen and (max-height: 600px) and (max-width: 1300px) {
  footer {
    width: 90vw;
  }
}
/* HIDE NAV MWNU TABLET   */
@media screen and (max-width: 1024px) {
  .main_footer {
    height: 100%;
    padding: 2rem;
  }
  footer {
    width: 95vw;
    grid-template-columns: repeat(2, 1fr);
    height: 60%;
  }
  /* .footer3 {
    display: none;
  } */
  /* .footer3_mobo {
    display: block;
    width: 100vw;
  }
  .main_contact_mobo {
    display: flex;
  } */
}
@media screen and (max-width: 600px) {
  .main_footer {
    height: max-content !important;
    padding: 2rem 1rem;
    overflow: hidden;
  }
  footer {
    width: 100vw;
    grid-template-columns: repeat(1, 1fr);
  }
  .last_div {
    padding: 1rem 0px;
    width: 95%;
    height: 20vh !important;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
  }

  .email_input {
    width: 200px;
    height: 40px;
  }
}

@media screen and (max-width: 320px) {
  .main_footer {
    height: max-content !important;
    padding: 3.5rem 1rem;
  }
  .email_sub {
    gap: 0.5rem;
    flex-direction: column !important;
  }
  .btn1 {
    padding: 9px 4px !important;
  }
  .last_div {
    gap: 0.5rem;
  }
}
