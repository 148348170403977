.Search-main-divv {
  padding-top: 5rem;
}
.srch-main-box {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100px;
  gap: 35px;
}
.srch-btn {
  width: 100px;
  height: 40px;
  background-color: #00ad5a;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border: none;
  border-radius: 4px;
}
.srch-btn:hover {
  background-color: limegreen;
}
.recent-srch-sec-divv {
  width: 80%;
}
.Recent-srch-main-div {
  display: flex;
  justify-content: center;
  /* height: 100%; */
  background-color: #f3f3f3;
  padding-bottom: 2rem;
}
.recent-heading-spann-tagg {
  font-size: 20px;
  font-weight: 500;
}
.plumber-single-text-boxx {
  width: auto;
  padding: 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
}
.plumber-single-text-boxx:hover {
  background-color: black;
  color: #fff;
}
.carpenter-header-divv {
  font-size: 12px;
}
.recent-heading-divv {
  height: 60px;
  display: flex;
  align-items: center;
}
.card-plumber-main-divv {
  width: 230px;
  height: 300px;
  background-color: #fff;
}
.AvailableAdvocate-main-section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.AvailableAdvocate-main-div {
  display: flex;
  width: 80%;
  flex-direction: column;
  gap: 2rem;
}

.advocat-img {
  width: 100%;
  height: 169px;
}

.Available-Advocates-span {
  font-size: 27px;
  font-weight: 700;
  color: #1e2d38;
}

.img-span-donno {
  position: relative;
}

.on-span {
  position: absolute;
  left: 15px;
  top: 3rem;
  font-size: 21px;
  font-weight: 600;
  color: #ffffff;
}

.btn-on {
  position: absolute;
  right: 50px;
  top: 4rem;
  width: 202px;
  height: 45px;
  border-radius: 6px;
  border: none;
  outline: none;
  background: #ffffff;
  color: #006ebd;
  font-weight: 700;
  cursor: pointer;
}

.lorem-span-span-123 {
  position: absolute;
  left: 15px;
  top: 5rem;
  font-size: 13px;
  font-weight: 400;
  color: #d1e1ed;
  width: 518px;
}

.card-ineer-single-div {
  width: 270px;
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e5eff5;
  border-radius: 6px;
  background: #ffffff;
  gap: 1rem;
  cursor: pointer;
}

.card-ineer-single-div:hover {
  border: 1px solid #00ad5a;
}

.card-section-main-div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.item-image-card {
  width: 114px;
  height: 114px;
  object-fit: cover;
  border-radius: 50%;
}

.item-span-card {
  font-size: 18px;
  font-weight: 500;
  color: #1e2d38;
}
.plumber-srch-divv {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  cursor: pointer;
}
.item-paragraph-span {
  font-size: 14px;
  font-weight: 400;
  color: #6f778b;
  width: 236px;
}
.book-button-spann {
  width: 240px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #00ad5a;
  background-color: #fff;
  color: #00ad5a;
  font-weight: 500;
}
.book-button-spann:hover {
  background-color: #00ad5a;
  color: #fff;
}
.item-price-span {
  font-size: 16px;
  font-weight: 600;
  color: #006ebd;
}

.per-hour-span {
  font-size: 13px;
  font-weight: 600;
  color: #6f778b;
}
.span-clem-exp-divv {
  width: 100%;
}
.clean-exp-divv {
  font-size: 12px !important;
  font-weight: 700;
  font-family: Be Vietnam Pro;
}
.doller-thirty-span {
  font-size: 15px;
  font-weight: 500;
  color: #00ad5a;
}
.item-dono-div {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 6.5rem; */
}

.image-review-dv {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  gap: 5px;
}

.image-review-span {
  font-size: 14px;
  font-weight: 500;
  color: #1e2d38;
}

.svg-image-div {
  display: flex;
  justify-content: end;
  align-self: flex-end;
  padding-right: 0.8rem;
  cursor: pointer;
}

.name-span13 {
  font-size: 18px;
  font-weight: 700;
  color: #1e2d38;
}

.dolar-span-span1 {
  font-size: 16px;
  font-weight: 600;
  color: #006ebd;
}

.star-img-90 {
  width: 15px;
  height: 15px;
}
.i-t-a-a {
  outline: none;
  width: 260px;
  height: 30px;
  border: none;
}
.main_input_div_of_search {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1280px) {
  .recent-srch-sec-divv {
    width: 87%;
    height: 100%;
  }
}
@media screen and (max-width: 1114px) {
  .srch-main-box {
    gap: 15px;
  }
  .recent-srch-sec-divv {
    width: 90%;
  }
  .card-ineer-single-div {
    width: 260px;
  }
}

@media screen and (max-width: 885px) {
  .card-section-main-div {
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
  }

  .card-ineer-single-div {
    width: 250px;
  }

  .srch-main-box {
    flex-wrap: wrap;
    gap: 16px;
    height: max-content;
  }
  .lorem-span-span-123 {
    width: 430px;
    margin-top: 1.5rem;
  }
  .book-button-spann {
    width: 225px;
  }
  .on-span {
    width: 430px;
  }

  .btn-on {
    width: 192px;
    right: 20px;
  }

  .item-paragraph-span {
    text-align: center;
  }

  .advocat-img {
    object-fit: cover;
  }
  .recent-srch-sec-divv {
    width: 87%;
    height: 100%;
  }
}

@media screen and (max-width: 834px) {
  .card-ineer-single-div {
    width: 240px;
  }

  .btn-on {
    width: 182px;
    right: 10px;
  }
  .item-dono-div {
    gap: 5rem;
  }
  .book-button-spann {
    width: 207px;
  }
}

@media screen and (max-width: 820px) {
  .AvailableAdvocate-main-div {
    width: 92%;
  }
}

@media screen and (max-width: 800px) {
  .AvailableAdvocate-main-div {
    width: 94%;
  }
}

@media screen and (max-width: 768px) {
  .AvailableAdvocate-main-div {
    width: 97%;
  }

  .card-ineer-single-div {
    width: 233px;
  }
}

@media screen and (max-width: 440px) {
  body {
    overflow-x: hidden;
  }
  .srch-btn {
    width: 185px;
  }
  .srch-main-box {
    width: 85%;
    height: max-content;
    padding: 2rem 0rem;
  }
  .card-section-main-div {
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
    height: 100%;
  }

  .card-ineer-single-div {
    width: 270px;
  }

  .Available-Advocates-span-div {
    text-align: center;
  }

  .advocat-img {
    object-fit: cover;
    height: 195px;
  }

  .on-span {
    font-size: 16px;
    width: 255px;
    top: 1rem;
  }

  .btn-on {
    top: 8.5rem;

    width: 150px;
    height: 40px;
  }

  .lorem-span-span-123 {
    width: 240px;
    top: 3rem;
  }
  .main_input_div_of_search {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/* @media screen and (max-width: 428px) {
  .srch-main-box {
    padding: 1rem 0rem;
  }
} */
