.All-Conatinor-perfect-divv {
  width: 1050px;
  border-radius: 6px;
  border: 0.5px solid #ededed;
  background: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  /* margin-bottom: 2rem; */
}
.All-Containor-perfect-second-divv {
  width: 90%;
  margin-top: 2rem;
}
.All-Containor-perfect-second-divv > form {
  align-items: flex-start;
}
.editprofile2-main3-section4 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
}

.edit-profile-main {
  display: flex;
  gap: 8rem;
}
.editprofile2-main3-div {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.editprofile-span90 {
  font-size: 27px;
  font-weight: 700;
  color: #1e2d38;
}

.camimg2-img2 {
  width: 147px;
  height: 147px;
}

.camimg1-img1 {
  width: 38px;
  height: 38px;
}

.edit-profile-img-position-div {
  display: flex;
  gap: 4rem;
  justify-content: center;
}

.camimg1-camimg2-pdiv {
  position: relative;
  height: 100%;
}

.camimg1-div13 {
  position: absolute;
  top: 7rem;
  height: 100%;
  bottom: 0;
  right: 10px;
}

.input-text-father-div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.input-text-child2-div {
  display: flex;
  gap: 1.5rem;
}

.label-or-feild-div44 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.html12-label-span10 {
  font-size: 14px;
  font-weight: 500;
  color: #1e2d38;
}

.kathryn-input222 {
  width: 252px;
  height: 45px;
  border-radius: 6px;
  border: 1px solid #dbdde2;
  padding-left: 1rem;
  outline: none;
}

.kathryn-input222::placeholder {
  font-size: 15px;
  font-weight: 600;
  color: #1e2d38;
}

.label-or-feild-div30 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.kathryn-second-input-20 {
  border: 1px solid #dbdde2;
  width: 530px;
  height: 45px;
  border-radius: 6px;
  padding-left: 1rem;
  outline: none;
}

.kathryn-second-input-20::placeholder {
  font-size: 15px;
  font-weight: 600;
  color: #1e2d38;
}

.save-button25-25 {
  width: 243px;
  height: 45px;
  background-color: #006ebd;
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
}

.border-special-div {
  border-bottom: 1px solid #e2e2e2;
  width: 760px;
}

.Professional-Info {
  font-size: 27px;
  font-weight: 700;
  color: #1e2d38;
}

.Professional-Info-main-div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  gap: 3rem;
}

.Professional-Info-child-div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.rate-per-hour-dv {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.Rate-label {
  font-size: 14px;
  font-weight: 500;
  color: #1e2d38;
}

.Professional-Info-input {
  width: 343px;
  height: 45px;
  outline: none;
  border-radius: 6px;
  border: 1px solid #dbdde2;
}

.Professional-Info-input::placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #919ba1;
  padding-left: 1rem;
}

.Professional-Info-input2 {
  width: 343px;
  height: 45px;
  outline: none;
  border-radius: 6px;
  border: 1px solid #dbdde2;
}

.Professional-Info-input2::placeholder {
  width: 80px;
  height: 30px;
  padding-left: 1rem;
  font-size: 13px;
  font-weight: 500;
  color: #1e2d38;
}

.Professional-Info-input3 {
  width: 348px;
  height: 48px;
  outline: none;
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid #dbdde2;
}

.custom-select {
  font-size: 14px;
  font-weight: 400;
  color: #919ba1;
  padding-left: 1rem;
}

.add-logo-pp {
  width: 24px;
  height: 24px;
}

.add-logo-span {
  font-size: 15px;
  font-weight: 500;
  color: #006ebd;
}

.add-logo-span-div6 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.save-save2-button-div {
  margin-top: 1rem;
}

.save-save2-button {
  width: 343px;
  height: 45px;
  border: none;
  outline: none;
  color: #ffffff;
  background-color: #006ebd;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}

.text-area-main-div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.text-area-child-div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.text-area50-text {
  width: 400px;
  height: 182px;
  border-radius: 6px;
  border: 1px solid #dbdde2;
  outline: none;
  resize: none;
  box-sizing: border-box;
  padding: 16px;
}

.text-area50-text::placeholder {
  font-size: 14px;
  font-weight: 500;
  color: #919ba1;
}

.text-area50-text::placeholder {
  font-size: 14px;
  font-weight: 500;
  color: #919ba1;
}

.hight-width-input {
  width: 346px;
  height: 205px;
  border: 1px dashed #9cacb8;
  background-color: #f6fbff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.inputf-img {
  width: 62px;
  height: 45px;
}

.drag-drop-text {
  font-size: 14px;
  font-weight: 400;
  color: #798a98;
}

.camimg1-img11 {
  width: 38px;
  height: 38px;
  display: flex;
  position: relative;
  top: 1rem;
  align-self: flex-end;
  right: 1rem;
}

.edit-profile-main27 {
  display: flex;
  gap: 4rem;
  justify-content: center;
  width: 100% !important;
}
.doler-hai-bhaiii {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  height: 45px;
  width: 345px;
  padding: 1rem;
  outline: none;
}

.kathryn-sec-input {
  border: 1px solid #dbdde2;
  width: 530px;
  height: 45px;
  border-radius: 6px;
  padding-left: 1rem;
  outline: none;
  cursor: not-allowed;
}

.kathryn-sec-input::placeholder {
  font-size: 15px;
  font-weight: 600;
  color: #1e2d38;
}
.Skills-main-divv-yya {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  width: 276px;
}
.dolar-bot-input {
  background: #ffffff;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  height: 39px;
  width: 295px;
  border: none;
  outline: none;
}
.skill-input-field {
  border: none;
  outline: none;
}
.Malte-pal-cls {
  border: none !important;
  outline: none;
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
}
.save-button-ppx {
  width: 243px;
  height: 45px;
  background-color: #00ad5a;
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

@media screen and (max-width: 1040px) {
  .All-Conatinor-perfect-divv {
    width: 60vw;
  }
}
@media screen and (max-width: 885px) {
  .Professional-Info-main-div {
    gap: 2rem;
  }
  /* .Professional-Info-child-div {
    width: 370px;
  } */
  .input-text-div-pap-div {
    flex-direction: column;
  }
  .edit-profile-main27 {
    gap: 2rem;
    width: 497px;
  }
  .All-Conatinor-perfect-divv {
    width: 564px;
  }
  .kathryn-input22 {
    width: 285px;
  }
  .kathryn-sec-input {
    width: 344px;
  }
  .doler-hai-bhaiii {
    width: 250px;
  }
  .dolar-bot-input {
    width: 215px;
  }
  .skill-input-field {
    width: 215px;
  }
  .Professional-Info-input3 {
    width: 250px;
  }
  .text-area50-text {
    width: 230px;
  }
}
/* @media screen and (max-width: 835px) {
  .doler-hai-bhaiii {
    width: 230px;
  }
  .Professional-Info-input3 {
    width: 230px;
  }
  .Professional-Info-child-div {
    width: 305px;
  }
  .edit-profile-main27 {
    width: 480px;
  }
  .All-Conatinor-perfect-divv {
    width: 500px;
  }
  .edit-profile-main27 {
    width: 438px;
  }
  .kathryn-sec-input {
    width: 287px;
  }
  .doler-hai-bhaiii {
    width: 210px;
  }
  .dolar-bot-input {
    width: 173px;
  }
  .skill-input-field {
    width: 173px;
  }
  .Professional-Info-input3 {
    width: 210px;
  }
  .Professional-Info-main-div {
    gap: 20px;
  }
  .save-button-ppx {
    width: 215px;
  }
} */
/* @media screen and (max-width: 820px) {
  .All-Conatinor-perfect-divv {
    width: 495px;
  }
  .Professional-Info-main-div {
    gap: 15px;
  }
}
@media screen and (max-width: 800px) {
  .All-Conatinor-perfect-divv {
    width: 483px;
  }
  .edit-profile-main27 {
    width: 432px;
  }
  .doler-hai-bhaiii {
    width: 205px;
  }
  .Professional-Info-input3 {
    width: 205px;
  }
  .text-area50-text {
    width: 220px;
  }
} */

@media screen and (max-width: 835px) {
  .edit-profile-main27 {
    gap: 1rem;
    width: 396px;
  }

  .All-Conatinor-perfect-divv {
    width: 443px;
  }

  .edit-profile-main27 {
    flex-direction: column;
  }
  .Professional-Info-main-div {
    flex-direction: column;
  }
  .doler-hai-bhaiii {
    width: 294px;
  }
  .Professional-Info-input3 {
    width: 294px;
  }
  .text-area50-text {
    width: 294px;
  }
}

@media screen and (max-width: 428px) {
  .edit-profile-main27 {
    flex-direction: column;
  }
  .kathryn-sec-input {
    width: 290px;
  }
  .kathryn-input22 {
    width: 290px;
  }
  .Professional-Info-main-div {
    flex-direction: column;
  }
  .text-area50-text {
    width: 344px;
  }
  .doler-hai-bhaiii {
    width: 290px;
  }
  .Professional-Info-input3 {
    width: 290px;
  }
  .text-area50-text {
    width: 290px;
  }
  .All-Conatinor-perfect-divv {
    width: 322px;
  }
}
@media screen and (max-width: 415px) {
  .All-Conatinor-perfect-divv {
    width: 305px;
  }
  .kathryn-input22 {
    width: 250px;
  }
  .kathryn-sec-input {
    width: 250px;
  }
  .doler-hai-bhaiii {
    width: 250px;
  }
  .Professional-Info-input3 {
    width: 250px;
  }
  .text-area50-text {
    width: 250px;
  }
  .save-button-ppx {
    width: 250px;
  }
}
@media screen and (max-width: 450px) {
  .All-Conatinor-perfect-divv {
    width: 285px;
  }
}

@media screen and (max-width: 375px) {
  .All-Conatinor-perfect-divv {
    width: 273px;
  }
}
@media screen and (max-width: 360px) {
  .All-Conatinor-perfect-divv {
    width: 263px;
  }
  .kathryn-input22 {
    width: 238px;
  }
  .kathryn-sec-input {
    width: 238px;
  }
  .editprofile-span90 {
    font-size: 23px;
  }
  .Professional-Info {
    font-size: 23px;
  }
  .doler-hai-bhaiii {
    width: 238px;
  }
  .Professional-Info-input3 {
    width: 238px;
  }
  .Ht-ml-for {
    width: 80px;
  }
  .text-area50-text {
    width: 238px;
  }
  .save-button-ppx {
    width: 238px;
  }
}
@media screen and (max-width: 320px) {
  .All-Conatinor-perfect-divv {
    width: 220px;
  }
  .cam-two-ha {
    height: 110px;
    width: 110px;
  }
  .profile-divv-photo {
    height: 40px;
    width: 40px;
    right: 31px;
    bottom: 30px;
  }

  .cam-one-add {
    right: 8px;
    height: 22px;
    width: 22px;
    top: 8px;
    bottom: 0;
  }
  .kathryn-input22 {
    width: 199px;
  }
  .kathryn-sec-input {
    width: 199px;
  }
  .doler-hai-bhaiii {
    width: 199px;
  }
  .Professional-Info-input3 {
    width: 199px;
  }
  .text-area50-text {
    width: 199px;
  }
  .save-button-ppx {
    width: 199px;
  }
  .dolar-bot-input {
    width: 152px;
  }
}
