.mybooking2-main-section1 {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
}

.mybooking2-child-section1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  width: 80%;
}

.mybooking2-single121-div {
  width: 68%;
}

.My-Bookings21 {
  font-size: 27px;
  font-weight: 700;
  color: #1e2d38;
}

.Upcoming-button22 {
  width: 531px;
  height: 45px;
  border-radius: 6px 0 0 6px;
  border: none;
  outline: none;
  background-color: #ffffff;
  font-weight: 600;
  color: black;
}

.completed-button22 {
  width: 531px;
  height: 45px;
  border-radius: 0 6px 6px 0;
  border: none;
  outline: none;
  background-color: #f0faf5;
  color: #00ad5a;
  font-weight: 600;
}

.main-div-of-all-card10 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.single-card-height-width-div {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1rem;
  border: 1px solid #e5eff5;
}

.inner22-div-card-content22 {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.henry-class-div {
  display: flex;
  gap: 1rem;
}

.name22-span-text-div {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.star-iamge23-review-div {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.image-reveiw-span66 {
  font-size: 12px;
  font-weight: 500;
  color: #1e2d38;
}

.dollar-price-span35 {
  font-size: 16px;
  font-weight: 600;
  color: #006ebd;
}

.perhour-55-span {
  font-size: 13px;
  font-weight: 500;
  color: #6f778b;
}

.chat-logo-btn-div {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-left: 2.5rem;
}

.completed-123-btn {
  width: 75px;
  height: 21px;
  color: #ffffff;
  background-color: #2ea63a;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  border: none;
  outline: none;
}

.date-time-main13-div {
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
}

.time-child10-div2 {
  display: flex;
  flex-direction: column;
}

.date5-span5 {
  font-size: 13px;
  font-weight: 400;
  color: #6f778b;
}

.jun5-span5 {
  font-size: 15px;
  font-weight: 600;
  color: #1e2d38;
}

.rating-button55 {
  width: 380px;
  height: 35px;
  border: 1px solid #006ebd;
  border-radius: 6px;
  color: #006ebd;
  background-color: #ffffff;
  font-weight: 600;
}

.rating-button55-div {
  margin-top: 0.5rem;
}

.item2-span-name {
  font-size: 18px;
  font-weight: 700;
  color: #1e2d38;
}

.span-green-upcoming-tagg-ppx {
  font-size: 12px;
  font-weight: 500;
  /* color: #1632c6; */
  color: #ffffff;
}

.Upcoming-wandh-set-divv {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upcoming-green-main-divv {
  background-color: #00ad5a;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  border-radius: 4px;
}

.single-card-height-width-div:hover {
  border: 1px solid #006ebd;
}

.star-img-98 {
  width: 70px;
  height: 14px;
}

.item2-image45 {
  width: 78px;
  height: 78px;
}

.rating-btnn-divv-ppx {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Btnn-ratingg-service {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #00ad5a;
  background-color: #ffffff;
  color: #00ad5a;
}
.Btnn-ratingg-service:hover {
  background-color: #00ad5a;
  color: #fff;
}
@media screen and (max-width: 885px) {
  .mybooking2-single121-div {
    width: 97%;
  }
  .Upcoming-button22 {
    width: 331px;
  }

  .completed-button22 {
    width: 331px;
  }
}

@media screen and (max-width: 835px) {
  .Upcoming-button22 {
    width: 366px;
  }

  .completed-button22 {
    width: 366px;
  }

  .single-card-height-width-div {
    width: 350px;
  }

  .rating-button55 {
    width: 350px;
  }
}
@media screen and (max-width: 800px) {
  .Upcoming-button22 {
    width: 352px;
  }

  .completed-button22 {
    width: 352px;
  }

  .single-card-height-width-div {
    width: 330px;
  }

  .rating-button55 {
    width: 330px;
  }

  .chat-logo-btn-div {
    padding-left: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .Upcoming-button22 {
    width: 337px;
  }

  .completed-button22 {
    width: 337px;
  }

  .single-card-height-width-div {
    width: 320px;
  }

  .rating-button55 {
    width: 320px;
  }

  .chat-logo-btn-div {
    padding-left: 1rem;
  }
}

@media screen and (max-width: 430px) {
  .main-div-of-all-card10 {
    grid-template-columns: repeat(1, 1fr);
  }

  .single-card-height-width-div {
    width: 360px;
  }

  .chat-logo-btn-div {
    padding-left: 1.5rem;
  }

  .rating-button55 {
    width: 360px;
  }

  .Upcoming-button22 {
    width: 213px;
  }

  .completed-button22 {
    width: 214px;
  }

  .mybooking2-single121-div {
    text-align: center;
  }
  #pagination {
    flex-direction: column;
    gap: 1rem !important;
  }
}

@media screen and (max-width: 415px) {
  .Upcoming-button22 {
    width: 206px;
  }

  .completed-button22 {
    width: 206px;
  }
}
@media screen and (max-width: 391px) {
  .single-card-height-width-div {
    width: 315px;
  }

  .chat-logo-btn-div {
    padding-left: 0.5rem;
  }

  .rating-button55 {
    width: 315px;
  }

  .completed-button22 {
    width: 195px;
  }

  .Upcoming-button22 {
    width: 195px;
  }
}
@media screen and (max-width: 376px) {
  .completed-button22 {
    width: 187px;
  }

  .Upcoming-button22 {
    width: 187px;
  }
}
@media screen and (max-width: 360px) {
  .single-card-height-width-div {
    width: 300px;
  }

  .chat-logo-btn-div {
    padding-left: 0rem;
  }

  .rating-button55 {
    width: 300px;
  }

  .completed-button22 {
    width: 180px;
  }

  .Upcoming-button22 {
    width: 180px;
  }
}

@media screen and (max-width: 320px) {
  .single-card-height-width-div {
    width: 270px;
  }

  .chat-logo-btn-div {
    padding-left: 0rem;
  }

  .rating-button55 {
    width: 270px;
  }

  .completed-123-btn {
    width: 70px;
    font-size: 12px;
  }

  .henry-class-div {
    gap: 0.5rem;
  }

  .completed-button22 {
    width: 160px;
  }

  .Upcoming-button22 {
    width: 160px;
  }
}
