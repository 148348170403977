.AdRegister-main-sec {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
}

.AdRegister-main-div {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
}

.ragiesrer-one {
  display: flex;
  width: 90%;
}

.haghit-ka-liya {
  height: 50px;
}

.blue-undur-line {
  background: #006ebd;
  border-radius: 30px;
  width: 240px;
  height: 4px;
  display: flex;
}

.div-he-div-he {
  display: flex;
  align-items: center;
}

.one-dada-span {
  width: 78px;
  height: 78px;
  background: #006ebd;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 25px;
  color: white;
}

.transprant-undur-line {
  background: #d9d9d9;
  border-radius: 30px;
  width: 240px;
  height: 4px;
  display: flex;
}

.two-dada-span {
  width: 78px;
  height: 78px;
  background: #ffffff;
  border: 5px solid #d9d9d9;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 25px;
  color: black;
}

.profile-info-register {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cemara-dono-add {
  position: relative;
  cursor: pointer;
  width: 147px !important;
  height: 147px !important;
  border-radius: 50% !important;
}
.Professional-personal-main {
  display: flex;
  width: 80%;
  justify-content: center;
}

.per-pro-main-btn-divv {
  width: 50%;
}
.Per-pro-button-one {
  width: 600px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border: none;
}

.Per-pro-button-two {
  width: 600px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border: none;
}
.per-divv-span-onee {
  color: #fff;
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
}
.per-divv-span-twoo {
  color: #fff;
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
}
.Per-pro-one-span {
  font-size: 20px;
  font-weight: 600;
}
.Pro-per-type-span-twoo {
  font-size: 20px;
  font-weight: 500;
}
.Per-pro-type-span {
  font-size: 20px;
  font-weight: 500;
}
.personaly-add {
  font-weight: 700;
  font-size: 27px;
  color: #1e2d38;
}
.underline-divv-per-pro {
  border: 2px solid #ece7fa;
  width: 93.3%;
}
.Create-an-acc-main-divv {
  display: flex;
  justify-content: center;
}
.cam-two-ha {
  height: 147px;
  width: 147px;
  border-radius: 50%;
}

.cam-one-add {
  position: absolute;
  right: 10px;
  height: 25px;
  width: 25px;
  top: 10px;
  bottom: 0;
  background-color: #00ad5a;
}
.profile-divv-photo {
  background-color: #00ad5a;
  height: 45px;
  width: 45px;
  position: absolute;
  right: 5px;
  bottom: 0;
  border-radius: 40px;
}
.input-from-r {
  display: flex;
  flex-direction: column;
}

.Full-Name-input-hova {
  background: #ffffff;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  height: 45px;
  width: 345px;
  padding: 1rem;

  outline: none;
}
.Full-Name-input-hova-added {
  background: #ffffff;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  height: 45px;
  width: 345px;
  padding: 1rem;
  border: none;
  outline: none;
}
.PhoneInputInput {
  border: none;
  outline: none;
}
.main-div-lena-pada {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  height: 45px;
  width: 345px;

  outline: none;
}

.adregister-div {
  background: #ffffff;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  height: 45px;
  width: 345px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Password-or-text-eksatha {
  height: 38px;
  width: 300px;
  border: none;
  outline: none;
}

.Continue-btn-adr {
  background: #00ad5a;
  border-radius: 6px;
  width: 343px;
  height: 45px;
  color: white;
  outline: none;
  border: none;
  font-weight: 700;
  font-size: 15px;
}

.all-input-span-font {
  font-weight: 500;
  font-size: 14px;
  color: #1e2d38;
}

@media screen and (max-width: 1280px) {
  .blue-undur-line {
    width: 200px;
  }

  .transprant-undur-line {
    width: 200px;
  }
  .Per-pro-button-one {
    width: 515px;
  }
  .Per-pro-button-two {
    width: 515px;
  }
  .underline-divv-per-pro {
    border: 2px solid #ece7fa;
    width: 84.3%;
  }
}

@media screen and (max-width: 913px) {
  .blue-undur-line {
    width: 130px;
  }

  .transprant-undur-line {
    width: 130px;
  }
}

@media screen and (max-width: 885px) {
  .blue-undur-line {
    width: 110px;
  }

  .transprant-undur-line {
    width: 110px;
  }
  .Per-pro-button-two {
    width: 355px;
  }
  .Per-pro-button-one {
    width: 355px;
  }
  .underline-divv-per-pro {
    width: 87%;
  }
}
@media screen and (max-width: 835px) {
  .underline-divv-per-pro {
    width: 92.5%;
  }
  .Per-pro-button-two {
    width: 360px;
  }
  .Per-pro-button-one {
    width: 360px;
  }
}

@media screen and (max-width: 820px) {
  .underline-divv-per-pro {
    width: 86.5%;
  }
  .Per-pro-button-two {
    width: 330px;
  }
  .Per-pro-button-one {
    width: 330px;
  }
}
@media screen and (max-width: 800px) {
  .underline-divv-per-pro {
    width: 89.5%;
  }
}
@media screen and (max-width: 768px) {
  .underline-divv-per-pro {
    width: 80%;
  }
  .blue-undur-line {
    width: 110px;
  }

  .transprant-undur-line {
    width: 110px;
  }

  .one-dada-span {
    width: 60px;
    height: 60px;
  }

  .two-dada-span {
    width: 60px;
    height: 60px;
  }

  .Per-pro-button-two {
    width: 280px;
  }
  .Per-pro-button-one {
    width: 280px;
  }
}

@media screen and (max-width: 570px) {
  .blue-undur-line {
    width: 70px;
  }

  .transprant-undur-line {
    width: 70px;
  }

  .one-dada-span {
    width: 60px;
    height: 60px;
  }

  .two-dada-span {
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 428px) {
  .Professional-personal-main {
    align-items: center;
    /* gap: 1rem;  */
  }
  .blue-undur-line {
    width: 45px;
  }
  .transprant-undur-line {
    width: 45px;
  }

  .personaly-add {
    font-size: 25px;
  }

  .one-dada-span {
    width: 60px;
    height: 60px;
  }

  .two-dada-span {
    width: 60px;
    height: 60px;
  }

  .Per-pro-button-two {
    width: 170px;
  }
  .Per-pro-button-one {
    width: 170px;
  }
  .Per-pro-type-span {
    font-size: 16px;
  }
  .per-divv-span-onee {
    width: 25px;
    height: 25px;
  }
  .Pro-per-type-span-twoo {
    font-size: 16px;
  }
  .per-divv-span-twoo {
    width: 25px;
    height: 25px;
  }
  .Per-pro-one-span {
    font-size: 16px;
  }
  .underline-divv-per-pro {
    width: 80%;
  }
  .Full-Name-input-hova {
    width: 290px;
  }
  .adregister-div {
    width: 290px;
  }
  .Password-or-text-eksatha {
    width: 225px;
  }
  .Continue-btn-adr {
    width: 290px;
  }
}
/* @media screen and (max-width: 415px) {
  .underline-divv-per-pro {
    width: 82.5%;
  }
  .Per-pro-button-two {
    width: 170px;
  }
  .Per-pro-button-one {
    width: 170px;
  }
}
@media screen and (max-width: 390px) {
  .Pro-per-type-span-twoo {
    font-size: 15px;
  }
  .Per-pro-type-span {
    font-size: 15px;
  }
  .underline-divv-per-pro {
    width: 78%;
  }
  .Per-pro-button-two {
    width: 150px;
    gap: 5px;
  }
  .Per-pro-button-one {
    width: 150px;
  }
  .Full-Name-input-hova {
    width: 279px;
  }
  .Password-or-text-eksatha {
    width: 265px;
  }
  .Continue-btn-adr {
    width: 310px;
  }
} */
@media screen and (max-width: 376px) {
  .blue-undur-line {
    width: 40px;
  }

  .transprant-undur-line {
    width: 40px;
  }

  .personaly-add {
    font-size: 20px;
  }

  .one-dada-span {
    width: 60px;
    height: 60px;
  }

  .two-dada-span {
    width: 60px;
    height: 60px;
  }

  .Per-pro-button-one {
    width: 155px;
  }
  .Per-pro-type-span {
    font-size: 12px;
  }
  .Pro-per-type-span-twoo {
    font-size: 12px;
  }
  .Per-pro-button-two {
    width: 155px;
  }
  .underline-divv-per-pro {
    width: 83%;
  }
}

@media screen and (max-width: 360px) {
  .AdRegister-main-div {
    display: block;
  }
  .Full-Name-input-hova {
    width: 280px;
  }
  .adregister-div {
    width: 280px;
  }
  .Password-or-text-eksatha {
    width: 225px;
  }
  .Continue-btn-adr {
    width: 280px;
  }
  .underline-divv-per-pro {
    width: 86.5%;
  }
}
@media screen and (max-width: 320px) {
  .underline-divv-per-pro {
    width: 91%;
  }
  .Per-pro-button-two {
    width: 145px;
  }
  .Per-pro-button-one {
    width: 145px;
  }
  .Full-Name-input-hova {
    width: 267px;
  }
  .Password-or-text-eksatha {
    width: 220px;
  }
  .adregister-div {
    width: 267px;
  }
  .Continue-btn-adr {
    width: 270px;
  }
}
