.main_sec5_div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding: 3rem 2rem;
}

.sec5_sub_div {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.sec5_card {
  width: 240px !important;
  height: 300px !important;
  display: grid !important;
  grid-template-rows: 40% 60% !important;
  gap: 1rem !important;
  place-items: center !important;
  cursor: pointer;
  padding: 1rem;
}
.sec5_card:hover {
  border: 1px solid #00ad5a;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.sec5_card img {
  width: 100% !important;

  aspect-ratio: 3/2 !important;
  object-fit: contain !important;
}
.sec5_text p {
  text-align: center;
  color: #706c74;
  text-align: start !important;
  font-family: Be Vietnam Pro;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.sec5_text h4 {
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sec5_text {
  width: 95% !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  flex-direction: column;
  gap: 1rem;
}
.btn5 {
  width: 180px;
  height: 50px;
  /* padding: 13px 23px; */
  outline: none;
  border: none;
  background: #00ad5a;
  border-radius: 4px;
  color: #fff;
}
.btn5:hover {
  background-color: #fff;
  color: #00ad5a;
  border: 1px solid #00ad5a;
}

/* tablet style start here */
@media screen and (max-width: 1024px) {
  .sec5_sub_div {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
  }
  .sec5_card {
    width: 175px !important;
  }
  .sec5_card img {
    width: 96px;
    height: 96px;
  }
  .sec5_text {
    gap: 0.5rem;
  }
  .sec5_text h4 {
    font-size: 15px;
  }
  .sec5_text p {
    font-size: 10px !important;
  }
}
/* mobile style start here */
@media screen and (max-width: 600px) {
  .sec5_sub_div {
    width: 60%;
    display: grid;
    place-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  .sec5_card {
    width: 150px !important;
  }
  .sec5_card img {
    width: 80px;
    height: 80px;
  }
  .sec5_text h4 {
    font-size: 12px !important;
  }
}
