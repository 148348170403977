.register-main-section {
  width: 100%;
  height: 100%;
}

.both-main-div78 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.align-self-dib {
  padding-left: 1rem;
  align-self: flex-start;
}

.image-section-parent-div {
  width: 60%;
}

.group28-img-11 {
  width: 180px;
  height: 95px;
}

.fadvo-or-text-div {
  position: relative;
}

.register-text-div12 {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 80%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.span-span12-span {
  font-size: 30px;
  font-weight: 400;
  color: #ffffff;
  width: 430px;
}

.color-span12-span12 {
  color: #00ad5a;
}

.fadvo-image-image {
  width: 700px;
  height: 600px;
}

.input-field13-parent-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
}

.both-img-span-div {
  display: flex;

  justify-content: flex-start;
  gap: 0.5rem;
}

.back-both-span {
  font-size: 15px;
  font-weight: 700;
  color: #1e2d38;
}

.backarrow-img-img {
  width: 24px;
  height: 24px;
}

.Create-an-Account {
  font-size: 27px;
  font-weight: 700;
  color: #1e2d38;
}

.fifth-input-feild {
  width: 311px;
  height: 45px;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  outline: none;
  padding-left: 40px;
  box-sizing: border-box;
}

.nlogo-container-div {
  display: flex;
  align-items: center;
  position: relative;
}

.single-contendiv {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.nlogo-image {
  height: 24px;
  width: 24px;
}

.single-contendiv-div {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.register-btn-single {
  width: 311px;
  height: 45px;
  border-radius: 6px;
  background: #00ad5a;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  border: none;
  outline: none;
  cursor: pointer;
}

.Already-have-an-account {
  font-size: 15px;
  font-weight: 600;
  color: #1e2d38;
}

.login-attach-span {
  font-size: 15px;
  font-weight: 500;
  color: #00ad5a;
  cursor: pointer;
}

.blue-span-div {
  margin-top: 1rem;
  cursor: pointer;
}

.Backe-arww {
  width: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 884px) {
  .both-main-div78 {
    flex-direction: column;
    gap: 2rem;
  }

  .image-section-parent-div {
    width: 100%;
  }

  .fadvo-or-text-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .fadvo-image-image {
    width: 100%;
  }

  .register-text-div12 {
    gap: 4rem;
  }

  .input-field13-parent-div {
    width: 100%;
  }
}

@media screen and (max-width: 428px) {
  .group28-img-11 {
    width: 100px;
    height: 80px;
    padding-right: 1.5rem;
  }

  .span-span12-span {
    font-size: 25px;
    width: auto;
    padding-left: 1.5rem;
  }

  .register-text-div12 {
    gap: 0rem;
    align-items: center;
  }

  .input-field13-parent-div {
    width: 100%;
  }
}

@media screen and (max-width: 390px) {
  .register-text-div12 {
    gap: 1rem;
  }
}

@media screen and (max-width: 390px) {
  .both-main-div78 {
    height: 100vh;
  }
}
