.payment-main-section90 {
  width: max-content;
  height: 100%;
  border-radius: 6px;
  background: #fff;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 4rem;
}

.payment-main-dib90 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
}

.Payment-Options-span {
  font-size: 27px;
  font-weight: 700;
  color: #1e2d38;
}

.payment-input67 {
  border: 1px solid #e4e4e4;
  width: 450px;
  height: 60px;
  gap: 0.5rem;
  outline: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.jugad-input12 {
  width: 300px;
  border: none;
  outline: none;
}

.visaimg-img {
  width: 63px;
  height: 39px;
  padding-left: 0.5rem;
}

.jugad-input12::placeholder {
  font-size: 14px;
  font-weight: 700;
  color: #1e2d38;
}

.paymnet-three-main-div {
  /* display: flex;
  gap: 2rem; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 2rem;
}

.paymnet-add-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Add-New-Card-span {
  font-size: 15px;
  font-weight: 500;
  color: #00ad5a;
}

.add-logo-pls {
  width: 24px;
  height: 24px;
}

.add-logocard-div99 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.card_number {
  font-size: 15px;
  font-weight: 700;
}

@media screen and (max-width: 885px) {
  .payment-main-section90 {
    padding: 1rem;
  }
  .paymnet-add-div {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 94%;
  }
  .paymnet-three-main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 800px) {
  .payment-input67 {
    width: 432px;
  }
}
@media screen and (max-width: 768px) {
  .payment-input67 {
    width: 405px;
  }
}
@media screen and (max-width: 429px) {
  .paymnet-add-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .visaimg-img {
    width: 50px;
    height: auto;
    padding-left: 0.4rem;
  }

  .payment-input67 {
    width: 250px;
  }
  .paymnet-three-main-div {
    gap: 1rem;
  }

  .Payment-Options-span {
    font-size: 23px;
  }
  .hide_card_number {
    display: none;
  }
  .card_number {
    text-align: start;
    width: 120px;
    height: auto;
  }
}
@media screen and (max-width: 360px) {
  .payment-input67 {
    width: 236px;
  }
}

@media screen and (max-width: 320px) {
  .payment-input67 {
    width: 290px;
  }
  .Payment-Options-span {
    font-size: 21px;
  }
}
