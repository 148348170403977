.MAIN_FAQ {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 6rem 0rem;
}
.sub_main_div_FAQ {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 3rem;
}
.accordion_div_FAQ {
  width: 70%;
}
.main_Qus_div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.Qusetion_No {
  background-color: #1a59f3;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Qusetion_No > h3 {
  color: var(--Green, #ffffff);
  text-align: right;
  font-family: Be Vietnam Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ans_div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5rem;
}
.ans_div > p {
  width: 90%;
  font-size: 14px;
}
.arrow_ans {
  font-size: 22px;
  color: #1a59f3;
}
