.AdvocateDetail-main-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: max-content;
  margin-top: 7rem;
  overflow: hidden;
}

.AdvocateDetail-main-div {
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
}
.main_back_btn_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.back_bton {
  width: 50px;
  height: 30px;
  border-radius: 6px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.back_bton:hover {
  border: 1px solid #00ad5a;
}

.AdvocateDetail-second-div {
  display: flex;
  gap: 2rem;
}
.card-plumber-single-div {
  width: 270px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e5eff5;
  border-radius: 6px;
  background: #ffffff;
  gap: 0.5rem;
}
.span-para-lorem-brooklyn {
  color: #706c74;
}
.hire1-card-img {
  width: 132px;
  height: 132px;
}

.fav-star-imges {
  width: 70px;
  height: 14px;
}

.price-and {
  display: flex;
}

.maoincard-one {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.maincar-css-kaliya {
  background: #ffffff;
  border: 1px solid #e5eff5;
  border-radius: 6px;
  width: 220px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.About-span {
  font-weight: 700;
  font-size: 22px;
  color: #1e2d38;
  text-transform: uppercase;
}

.About-maindib-jocob {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
}

.redmore-spann {
  width: 838px;
  height: 100%;
  margin-top: 0.5rem;
}

.red-read-more {
  color: #7341ee;
  font-weight: 400;
  font-size: 14px;
}

.Skills-span {
  font-weight: 700;
  font-size: 22px;
  color: #1e2d38;
}

.Skills-div {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 2rem;
}

.Lorem-Ipsum-btn {
  border: 1px solid #d1d5de;
  border-radius: 6px;
  width: max-content;
  padding: 0.5rem;
  height: 37px;
  font-weight: 700;
  font-size: 13px;
  color: #616071;
}

.all-btn-din {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  margin-top: 1rem;
}

.Resolution-span {
  font-weight: 700;
  font-size: 22px;
  color: #1e2d38;
}

.Resolution-div {
  width: 838px;
  height: 89px;
  margin-top: 3rem;
}

.Contrary-span {
  font-weight: 400;
  font-size: 14px;
  color: #6f778b;
}

.Check-Availability-btn {
  color: #00ad5a !important;
  border: 2px solid #00ad5a !important;
  background: #fff !important;
  border-radius: 6px !important;
  width: 233px !important;
  height: 45px !important;
  outline: none !important;
  font-weight: 700 !important;
  font-size: 15px !important;
}
.Check-Availability-btn:hover {
  color: #fff !important;
  background-color: #00ad5a !important;
}

.Carpenter-plumber-img-span {
  display: flex;
  flex-direction: column;
}
.Modal-check-and-book-divv {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 20px;
}
.book-button-sec-check-spann {
  width: 240px;
  height: 45px;
  border-radius: 4px;
  border: 1px solid #00ad5a;
  background-color: #00ad5a;
  color: #fff;
  font-weight: 500;
}

.Work-images-divv-here {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.Work-images-of-workers {
  width: 150px;
}
.rating-and-star-spann {
  font-size: 14px;
}
.all-apn-immg-car-plu-main {
  display: flex;
  /* width: 250px; */
  height: 80px;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
}
.all-span-booking-time-divv {
  display: flex;
  width: 100%;
  justify-content: center;
}
.model-img {
  width: 343px;

  background: #ffffff;
  border: 1px solid #e5eff5;
  border-radius: 6px;
  justify-content: center;
  margin-bottom: 1rem;
}
.brok-spann-tagg-ppyy {
  font-weight: 500;
}
.star-span-number-text-divv {
  display: flex;
  align-items: center;
  gap: 5px;
}
/* .Spann-doller-merge-divv-ppx {
  gap: 13rem;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.doller-span-main-divv-ppx {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.moidel-user {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.selected-time-btn {
  background-color: #00ad5a;
  color: #fff;
}
.hire1-model-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.dolar-span {
  color: rgba(0, 110, 189, 1);
  font-weight: 400;
  font-size: 14px;
}

.Availability-span-second {
  font-weight: 700;
  font-size: 22px;
  color: #1e2d38;
}

.model-ko-use-kiyA {
  margin-top: 1rem;
}

.Today-span {
  color: #00ad5a;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.Tomorrow-span {
  color: #706c74;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.Today-tommoro-span {
  display: flex;
  gap: 2rem;
  justify-content: center;
  width: 63%;
}

.time-apoy-mant {
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  width: 164px;
  height: 40px;
  color: black;
  font-size: 15px;
  outline: none;
  background-color: #fff;
}

/* .time-apoy-mant:hover {
  background: #00ad5a;
  color: #ffffff;
} */

.Contrary-spann {
  width: 834px;
}

.all-time-btn {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.Span-divv-Avail-pay {
  display: flex;
  justify-content: center;
  width: 55%;
}
.Avail-time-span {
  font-size: 23px;
  font-weight: 500;
}
.check-book-btnn {
  background: #00ad5a;
  border-radius: 6px;
  width: 345px;
  height: 45px;
  color: white;
  font-weight: 700t;
  font-size: 15px;
  border: none;
  outline: none;
}

.line-span {
  width: 100%;
  margin-top: 2rem;
  border: 1px solid #e2e2e2;
}
.Book-check-next-btnn-divv {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.Reviews-span {
  color: #1e2d38;
  font-weight: 700;
  font-size: 22px;
}

.Exceptional-span {
  color: #0d960a;
  font-weight: 400;
  font-size: 20px;
}

.Exceptional-rewive {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: space-between;
}

.do-rewive {
  color: #1e2d38;
  font-weight: 700;
  font-size: 13px;
}

.second-do-bar {
  width: 105px;

  height: 21px;
}

.gryy-line-hs {
  background: #e8e8e8;
  border-radius: 10px;
  width: 249px;
  height: 5px;
}

.grya-ab-ha {
  background: #0d960a;
  border-radius: 10px;
  width: 249px;
  height: 5px;
  z-index: 55;
}

.reweive-main {
  display: flex;
  gap: 1rem;
}

.rewive-user {
  width: 51px;
  height: 51px;
}

.Warren-span-rewvi {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.Wade-span {
  color: #171310;
  font-weight: 700;
  font-size: 16px;
}

.dec-date {
  color: #706c74;
  font-weight: 400;
  font-size: 13px;
}

.venenatis-span {
  color: #706c74;
  font-weight: 400;
  font-size: 14px;
}

.line-div {
  width: 100%;
  border: 1px solid #e2e2e2;
}

.Warren-main-div {
  display: flex;
  justify-content: space-between;
}

.date-and-name {
  display: flex;
  flex-direction: column;
}

/* Rating Css */
.jermo-reviews-date-june-divv {
  display: flex;
}

.Bell-rev-date-june-divv {
  width: 100%;
}

.Reviews-profile-imgg {
  width: 35px;
  height: 35px;
  border-radius: 18px;
}

.Reviews-jermo-bell-mian-divv {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}
.Jerome-Bell-spnn-name {
  font-weight: 500;
  font-size: 19px;
}
.date-jermo-text-june-divv {
  width: 102px;
}

.lorem-jermo-spn-divv-aat {
  color: #595959;
}
.lorem-date-jermo-divv {
  margin-left: 2.8rem;
}
.Underline-main-divv {
  border-radius: 5px;
  width: 100%;
  height: 1px;
  /* outline: none; */
  background-color: #dfdfdf;
}
.Reviews-see-all-rating-main-div {
  width: 100%;
}
.Jerome-prof-rating-star-divv {
  display: flex;
  gap: 3px;
}
.Lorem-read-mor-btnn {
  border: transparent;
  background-color: transparent;
  color: #00ad5a;
}
.Read-less-arrow-span {
  font-weight: 500;
  font-size: 16px;
  color: #00ad5a;
}
.norecorddiv > h1 {
  font-size: 25px;
  color: #a1a1a1;
}
@media screen and (max-width: 885px) {
  .About-maindib-jocob {
    width: 70%;
  }
  .AdvocateDetail-second-div {
    margin: 2rem;
  }
  .AdvocateDetail-main-div {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .redmore-spann {
    width: 100%;
  }
  .Contrary-spann {
    width: 100%;
  }
  .all-btn-din {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  .Resolution-Experience-spann-divv {
    width: 100%;
  }

  .Exceptional-rewive {
    width: 100%;
  }
  .card-plumber-single-div {
    width: 279px;
  }
  .Skills-div {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 820px) {
  .card-plumber-single-div {
    width: 258px;
  }
}
@media screen and (max-width: 800px) {
  .card-plumber-single-div {
    width: 236px;
  }
}
@media screen and (max-width: 769px) {
  .About-maindib-jocob {
    width: 60%;
  }

  .AdvocateDetail-main-div {
    width: 80%;
    align-items: center;
    justify-content: center;
  }

  .redmore-spann {
    width: 500px;
  }

  .all-btn-din {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .Resolution-div {
    width: 500px;
  }

  .Exceptional-rewive {
    width: 100%;
  }
  .AdvocateDetail-second-div {
    gap: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .AdvocateDetail-second-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  .date-jermo-text-june-divv {
    width: 182px;
  }
  .About-maindib-jocob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
  }
  .About-maindib-jocob {
    width: 100%;
  }

  .redmore-spann {
    width: 100%;
  }

  .all-btn-din {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .Resolution-div {
    width: 100%;

    height: 100%;
  }

  .Exceptional-rewive {
    width: 100%;
  }

  .Skills-div {
    margin-top: 6rem;
    width: 70%;
  }
  .book-button-sec-check-spann,
  .Check-Availability-btn {
    width: 194px !important;
  }
  .Contrary-spann {
    width: 100%;
  }
  .Modal-check-and-book-divv {
    flex-direction: column;
  }
  .redmore-spann {
    width: 100%;
  }
  .back_bton {
    /* width: 100%; */
    /* justify-content: flex-start; */
  }
}

/* @media screen and (max-width: 390px) {
  .About-maindib-jocob {
    width: 100%;
  }
  .book-button-sec-check-spann {
    width: 158px;
  }

  .Contrary-spann {
    width: 100%;
  }
  .plumber-single-text-boxx {
    width: 92px;
  }
  .Check-Availability-btn {
    color: #00ad5a !important;
    border: 2px solid #00ad5a !important;
    background: #fff !important;
    border-radius: 6px !important;
    width: 249px !important;
    height: 45px !important;
    outline: none !important;
    font-weight: 700 !important;
    font-size: 12px !important;
  }
  .Resolution-Experience-spann-divv {
    width: 80%;
  }
  .Modal-check-and-book-divv {
    flex-direction: column;
  }
}
@media screen and (max-width: 376px) {
  .About-maindib-jocob {
    width: 70%;
  }
  .plumber-single-text-boxx {
    width: 130px;
  }
  .Contrary-spann {
    width: 100%;
  }
  .book-button-sec-check-spann {
    width: 165px;
  }
  .Avail-time-span {
    font-size: 22px;
  }
  .check-book-btnn {
    width: 280px;
  }
  .Check-Availability-btn {
    color: #00ad5a !important;
    border: 2px solid #00ad5a !important;
    background: #fff !important;
    border-radius: 6px !important;
    width: 349px !important;
    height: 45px !important;
    outline: none !important;
    font-weight: 700 !important;
    font-size: 12px !important;
  }
}
@media screen and (max-width: 360px) {
  .About-maindib-jocob {
    width: 64%;
  }
  .Contrary-spann {
    width: 64%;
  }
  .book-button-sec-check-spann {
    width: 142px;
  }
  .plumber-single-text-boxx {
    width: 123px;
  }
  .Check-Availability-btn {
    color: #00ad5a !important;
    border: 2px solid #00ad5a !important;
    background: #fff !important;
    border-radius: 6px !important;
    width: 349px !important;
    height: 45px !important;
    outline: none !important;
    font-weight: 700 !important;
    font-size: 12px !important;
  }
}
@media screen and (max-width: 320px) {
  .About-maindib-jocob {
    width: 58%;
  }
  .plumber-single-text-boxx {
    width: 111px;
  }
  .Skills-span {
    margin-top: 1rem;
    width: 70%;
  }
  .Contrary-spann {
    width: 56%;
  }
  .book-button-sec-check-spann {
    width: 111px;
  }
  .Spann-doller-merge-divv-ppx {
    gap: 9rem;
  }
  .model-img {
    width: 277px;
  }
  .Jerome-Bell-spnn-name {
    font-size: 15px;
  }
  .Check-Availability-btn {
    color: #00ad5a !important;
    border: 2px solid #00ad5a !important;
    background: #fff !important;
    border-radius: 6px !important;
    width: 349px !important;
    height: 45px !important;
    outline: none !important;
    font-weight: 700 !important;
    font-size: 12px !important;
  }
} */

.clean-exp-divv_sub {
  /* width: 40%; */
  font-size: 14px;
  font-weight: 500;
  color: #414141;
  font-family: Be Vietnam Pro;
}
.clean-exp-divvv {
  font-size: 14px;
  font-weight: 500;
  color: #414141;
  font-family: Be Vietnam Pro;
}

.Spann-doller-merge-divv-ppx {
  display: grid;
  grid-template-columns: 75% 25%;

  width: 100%;
  place-items: center;
}

.span-clem-exp-divv {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 1rem;
}
