.about_main_section {
  width: 100%;
  height: max-content !important;
  padding: 6rem 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub_div_aboutsection {
  width: 80%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
}

/* section one syatle start here */
.section1 {
  width: 100%;
  display: grid;
  place-items: center;
  gap: 1.5rem;
  grid-template-columns: 50% 50%;
}
.main_sec1_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.sec1_text {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
  flex-direction: column;
}
.sec1_text > h1 {
  color: #14161b;
  font-family: Be Vietnam Pro;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 114%;
}
.sec1_text > p {
  color: #706c74;
  font-family: Be Vietnam Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  /* line-height: 32px; */
}
/* section two syatle start here */
.section2 {
  width: 100%;
  display: grid;
  place-items: center;
  grid-template-columns: 50% 50%;
  /* padding: 1rem; */
}
.sec2_text {
  width: 95%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
}
.sec2_text > h1 {
  color: #14161b;
  font-family: Be Vietnam Pro;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 114%;
}
.sec2_text > p {
  color: #706c74;
  font-family: Be Vietnam Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
/* section three syatle start here */
.section3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2rem;
}
.sec3_div {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.sec3_heading > h1 {
  color: var(--Black, #14161b);
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.sec3_count_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.Clients,
.Project,
.Average,
.Service {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.3rem;
}

.Clients > h1,
.Project > h1,
.Average > h1,
.Service > h1 {
  color: var(--Black, #14161b);
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Clients > p,
.Project > p,
.Average > p,
.Service > p {
  color: var(--Black, #14161b);
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
/* section three syatle start here */
.section4 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sec4img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sec4img > img {
  width: 100%;
  aspect-ratio: 2/1;
  object-fit: contain;
}
.section5 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2rem;
}
.sec5_text {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.sec5_text > h1 {
  color: var(--Black, #14161b);
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.sec5_text > p {
  color: var(--Grey, #706c74);
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
}

/*  TABLET AND   */
@media screen and (max-width: 1024px) {
  .section1,
  .section2 {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    padding: 0rem;
    place-items: start;
  }
  .sec1_text > h1,
  .sec2_text > h1 {
    color: #14161b;
    font-family: Be Vietnam Pro;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 114%;
  }
  .sec1_text > p,
  .sec2_text > p {
    color: #706c74;
    font-family: Be Vietnam Pro;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
  }
  .sec3_count_box {
    align-items: center;
    justify-content: center;
    gap: 2em;
  }
  .Clients > h1,
  .Project > h1,
  .Average > h1,
  .Service > h1 {
    color: var(--Black, #14161b);
    text-align: center;
    font-family: Be Vietnam Pro;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .Clients > p,
  .Project > p,
  .Average > p,
  .Service > p {
    width: 130px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .sec5_text {
    width: 100%;
  }
  .sec5_text > h1 {
    color: var(--Black, #14161b);
    text-align: center;
    font-family: Be Vietnam Pro;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .sec5_text > p {
    color: var(--Grey, #706c74);
    text-align: center;
    font-family: Be Vietnam Pro;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
  }
}
/*  MOBILE AND   */
@media screen and (max-width: 600px) {
  .about_main_section {
    padding: 6rem 1rem;
  }
  .sub_div_aboutsection {
    width: 95%;
  }
  .sec1_img,
  .sec2_img {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sec1_img > img,
  .sec2_img > img {
    width: 100%;
    aspect-ratio: 3/3;
    object-fit: cover;
  }
  .sec1_text > h1,
  .sec2_text > h1 {
    font-size: 17px;
  }
  .sec3_count_box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .sec1_text > p,
  .sec2_text > p {
    font-size: 10px;
  }
  .sec3_heading > h1 {
    font-size: 17px;
  }
  .section5 {
    padding: 0rem;
  }
  .sec5_text > h1 {
    font-size: 17px;
  }
  .sec5_text > p {
    font-size: 10px;
  }
  .Clients > h1,
  .Project > h1,
  .Average > h1,
  .Service > h1 {
    font-size: 17px;
  }
}
