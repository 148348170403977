/* verification.css */
.main_send_otp_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp_sub_main_div {
  text-align: center;
}

.otp-input {
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.verify-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.verify-btn:hover {
  background-color: #0056b3;
}

.verification-status {
  margin-top: 10px;
  font-weight: bold;
}
.sub_verfy > OTPInput > input {
  border: 1px solid #00ad5a !important;
}
