.card-sec-main-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding-bottom: 1.5rem;
}

.card-inr-single-div {
  width: 240px;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e5eff5;
  border-radius: 6px;
  background: #ffffff;
  gap: 0.5rem;
}

.book-btnn-spann {
  width: 194px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #00ad5a;
  background-color: #fff;
  color: #00ad5a;
  font-weight: 500;
}

.item-para-span {
  font-size: 14px;
  font-weight: 400;
  color: #6f778b;
  width: 190px;
}

.item-clear-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4.5rem;
}

.recent-search-sec-div {
  width: 100%;
}

@media screen and (max-width: 885px) {
  .card-inr-single-div {
    width: 220px;
  }
}
@media screen and (max-width: 440px) {
  .card-sec-main-div {
    gap: 1rem;
  }
}
