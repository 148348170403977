.main_service_section {
  width: 100%;
  height: auto;
  padding: 6rem 0rem 2rem 0rem;
  background-color: #f3f3f3;
}
.noDATA_text {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: 600;
  color: #969696;
  font-family: Be Vietnam Pro;
}
/* 1A59F3 */

.fordisable {
  padding: 5px;
  background-color: #1a59f3;
  border: none;
  outline: none;
  border-radius: 6px;
}
.pagenumber {
  font-size: 20px;
  font-weight: 600;
  color: #000000d2;
  font-family: Be Vietnam Pro;
}
.totalpagenumber {
  font-size: 15px;
  font-weight: 500;
  color: #5e5d5dd2;
  font-family: Be Vietnam Pro;
}
.category_name_div {
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.description_div {
  width: 80%;
}
