.main_div_sec3 {
  background-color: #edf9f3;
  width: 100vw;
  height: 554px;
  padding: 0rem 0;
  display: flex;
  align-items: self-end;
  justify-content: center;
}
.sub_main_div_sec3 {
  width: 80vw;
  display: flex;
  align-items: self-end;
  justify-content: space-evenly;
}
.abt_btn > button {
  display: inline-flex;
  padding: 5px 10px;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 6px;
  outline: none;
  background: #00ad5a;
  color: #fff;
  cursor: pointer;
}
.abt_btn > button:hover {
  background-color: #fff;
  color: #00ad5a;
  border: 1px solid #00ad5a;
  transition: all 0.5ms ease-in;
}

.text_box_sec3 {
  padding: 1rem 0rem;
}
.text_box_sec3 h2 {
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text_box_sec3 p {
  color: #626c67;
  font-family: Be Vietnam Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 160% */
}
.sec3_left_side_text {
  width: 50%;
  height: 100%;
  padding: 3rem 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.work_category {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
.w_butn {
  width: 222px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #d2d2d2;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.data_box {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
/* img style  */

.sec3_right_side_img {
  width: 30%;
  display: flex;
  align-items: self-end;
  justify-content: center;
  padding: 0 2rem;
}
.sec3_right_side_img > img {
  width: fit-content;
  height: auto;
}

/* tablet style start here */
@media screen and (max-width: 1024px) {
  .sec3_right_side_img {
    display: none;
  }
  .sub_main_div_sec3 {
    width: 90vw;
    align-items: center;
  }
  .sec3_left_side_text {
    width: 80%;
    align-items: flex-start;
  }
  .main_div_sec3 {
    align-items: center;
  }
}
/* mobile style start here */
@media screen and (max-width: 600px) {
  .main_div_sec3 {
    width: 100%;
    height: 100%;
  }
  .sub_main_div_sec3 {
    width: 95vw;
  }
  .work_category {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    place-items: center;
  }
  .sec3_left_side_text {
    width: 100%;
  }
  .text_box_sec3 h2 {
    font-size: 22px;
  }
  .text_box_sec3 p {
    font-size: 10px;
  }
}
