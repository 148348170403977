.main_div_trems {
  width: 100vw;
  height: max-content;
  padding-top: 7rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inner_div_trems {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.heading_text {
  width: max-content;
  padding: 1rem;
  border-bottom: 1px solid #000000;
}
.heading_text > h1 {
  color: #000000;
  text-align: center;
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
.inner_text_main_div {
  width: 80%;
  color: #1f1f1f;
  padding: 1rem;
  text-align: start;
  font-size: 18px;
  font-family: "Be Vietnam Pro", sans-serif;
  line-height: 40px;
}

.inner_text_main_div::-webkit-scrollbar {
  display: none !important;
}

@media screen and (max-width: 430px) {
  .heading_text > h1 {
    color: #000000;
    text-align: center;
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
  .inner_text_main_div {
    padding: 0;
  }
}
