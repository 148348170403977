#nav {
  background: #1a59f3;
  width: 100vw;
  height: 5rem;
  width: 100vw;
  height: 5rem;
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  box-shadow: 2px 2px 20px #000;

  transition: var(--transition);
}

/* .nav__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
} */
.nav_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
}
li {
  list-style: none;
}
.nav__link {
  text-decoration: none;
  color: #fff;
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 1rem;
}
.nav__link:hover {
  color: white !important;
}

/* drop-down styel start here */
/* 
.dropdown
  float: left;
  overflow: hidden;
*/
.dropdown {
  width: auto !important;
  background: transparent !important;
  color: white;
  border: none;
  outline: none;
}
.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  outline: none;
  color: rgb(0, 0, 0);
  background-color: inherit;
  font-family: "Be Vietnam Pro", sans-serif;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: rgb(255, 255, 255) !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-family: "Be Vietnam Pro", sans-serif;
}

.topnav a:hover,
.dropdown:hover {
  color: white;
}

.dropbtn {
  color: #000;
}

.dropdown-content a:hover {
  background-color: #1a59f3;
  color: #ffffff;
  box-shadow: 2px 2px 20px #000;
}

.dropdown:hover .dropdown-content {
  display: block;
  /* margin-top: 0.3rem; */
}

.dropdown-item {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
/* dropdown style and  */

/* btn style */
.btn1 {
  display: flex !important;
  padding: 13px 25px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px !important;
  border-radius: 4px !important;
  background: #00ad5a !important;
  border: none !important;
  color: #fff !important;
  font-family: "Be Vietnam Pro", sans-serif !important;
  cursor: pointer !important;
}
.btn1:hover {
  background: #fff !important;
  color: #00ad5a !important;
  box-shadow: 0px 8px 16px 0px #00000033 !important;
  transition: all 0.3s ease-in !important;
}
.btn2:hover {
  background: #00ad5a;
  color: #fff;
  box-shadow: 0px 8px 16px 0px #00000033;
  transition: all 0.3s ease-in;
}
.btn2 {
  cursor: pointer;
  display: inline-flex;
  padding: 13px 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #fff;
  border: none;
  color: #00ad5a;
  font-weight: 300;
  font-size: 13px;
  font-family: "Be Vietnam Pro", sans-serif;
}
.menubtn {
  display: none;
}
.btn_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.Logo-Phone-Divv {
  display: none;
}
.srch-loin-pc {
  display: none;
}
.Login-search-green {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
}
.btn-secondary {
  background-color: transparent !important;
  border: none !important;
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: #ffffff00 !important;
  border-color: none !important;
  box-shadow: none !important;
}
/* HIDE NAV MWNU TABLET AND MOBILE  */
@media screen and (max-width: 1024px) {
  /* .nav_menu {
    display: none;
  } */

  .nav__container {
    width: 100%;
    justify-content: space-around;
  }
  .nav_menu {
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #0048ff2d;
    background-color: blur;
    backdrop-filter: blur(10px);
    width: 30%;
    padding: 2.5rem 2.5rem !important;
    transform: translate(-100%);
    transition: all 0.3s ease-in;
    z-index: 1;
  }

  .Search-login-Nav-divv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
  .mobilemenu {
    transform: translate(0%);
  }
  .menubtn {
    display: block;
    color: #fff;
  }
  .btn_div {
    flex-direction: column;
    align-items: flex-start;
  }
  .btn1 {
    padding: 8px 16px !important;
  }
  .btn2 {
    padding: 8px 16px;
  }
  .loginbtn {
    color: white;
    background-color: transparent;

    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loginicon {
    width: 30px !important;
    height: 35px !important;
  }
}

/* HIDE NAV MENU  MOBILE  */
@media screen and (max-width: 885px) {
  .nav_menu {
    width: 100%;
    justify-content: flex-start;
  }
  .srch-loin-pc {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .Login-search-green {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .Logo-lap-Divvv {
    display: none;
  }
  .Logo-Phone-Divv {
    display: block;
  }
  .loginbtn {
    color: white;
    background-color: transparent;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loginicon {
    width: 30px !important;
    height: 35px !important;
  }
  .drop_tittle {
    display: none;
  }
  .dropdown_div {
    width: 20px;
  }
  #nav-dropdown-dark-example {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start;
    width: 101px;
  }
  .profile_div {
    gap: 10px;
  }
  .Srch-noti-guy-divv {
    display: none !important;
  }
  .dropdownsecond {
    position: absolute !important;
    inset: 20px -15px auto auto !important;
    transform: translate3d(198.5px, 6px, 0px) !important;
    font-size: 10px !important;
    background-color: white;
  }
  .dropdown-menu {
    position: absolute !important;
    inset: 20px 5px auto auto !important;
    transform: translate3d(78.5px, 6px, 0px) !important;
    font-size: 10px !important;
  }
}
