.main_chat_container {
  width: 100%;
  height: 50px;
  background-color: aqua;
}
.main_header_div_of_chat {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chatboxheader_chatscreen {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  padding: 10px;
  box-shadow: #63636333 0px 2px 8px 0px;
  border-radius: 8px;
}
.chatboxheader_chatscreen > img {
  width: 65px;
  height: 65px;
  object-fit: cover;
  border-radius: 50%;
}
.chatboxheader_chatscreen > h3 {
  font-size: 26px;
}
