.main_div_sec6 {
  width: 100vw;
  height: 500px;
  background: #fbf6f3;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.sub_div_sec6 {
  width: 80%;
  height: 100%;
  /* background-color: blueviolet; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
}
.right_side_text {
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}
.right_side_text > .text_div > h5 {
  font-family: Be Vietnam Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.left_side_img {
  width: 40%;
  height: 108%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.text_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

/* tablet style start here */
@media screen and (max-width: 1024px) {
  .right_side_text > .text_div > h5 {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .sub_div_sec6 {
    position: relative;
  }
  .right_side_text {
    position: absolute;
    right: 50%;
  }

  .left_side_img {
    position: absolute;
    left: 50%;
  }
}
/* tablet style start here */
@media screen and (max-width: 600px) {
  .right_side_text {
    width: 100%;
    position: absolute;
    right: 0%;
  }

  .left_side_img {
    display: none;
  }
  .main_div_sec6 {
    width: 100vw;
    height: 300px;
  }
  .btn5 {
    width: 140px;
    height: 45px;
  }
}
