.main_sec4_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 3rem 2rem;
}
.sub_sec4_div {
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.sub_sec4_div > h2 {
  color: var(--black, #14161b);
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.card_box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.card_div {
  width: 370px;
  height: 389px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card_div > img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}
.card_div::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 -75px 20px 0px #000000c1;
  z-index: 1;
}

.card_data_sec4 {
  width: 100%;
  position: absolute;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 8;
}
.text_sec4_card > h4 {
  color: #fff;
  font-family: Be Vietnam Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.raiting {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4rem;
}
.raiting > p {
  color: #fff;
  font-family: Be Vietnam Pro;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}
.card_sec4_box {
  display: flex;
  align-items: center;
  gap: 3rem;
}
.btn4 {
  padding: 13px 20px;
  outline: none;
  border: none;
  background-color: #00ad5a;
  border-radius: 4px;
  color: #fff;
}
.btn4:hover {
  background-color: #fff;
  color: #00ad5a;
  transition: all 5ms ease-in;
}
.ccard_box {
  display: none;
}
/* tablet style start here */
@media screen and (max-width: 1024px) {
  .card_box {
    flex-wrap: wrap;
  }
  .card_div {
    width: 270px;
    height: 279px;
  }
  .text_sec4_card > h4 {
    font-size: 11px;
    font-weight: 600;
  }
  .raiting > p {
    font-size: 10px;
  }
  .btn4 {
    padding: 8px 15px;
  }
}
/* mobile style start here */
@media screen and (max-width: 600px) {
  .main_sec4_div {
    height: max-content;
  }
  .sub_sec4_div {
    height: max-content;
  }
  .card_box {
    display: none;
  }
  .ccard_box {
    /* overflow: hidden; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 270px;
    height: 270px;
  }
  .main_swiper_box {
    width: 270px;
    height: 270px;
    overflow: hidden;
    position: relative;
  }
  .main_swiper_box::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 -75px 20px 0px #000000c1;
    z-index: 1;
  }
  .main_swiper_box > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
  }
  .swiper > img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .sub_sec4_div > h2 {
    color: #14161b;
    text-align: center;
    font-family: Be Vietnam Pro;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
