.deals_details_sec {
  width: 100vw;
  height: max-content;
  padding: 7rem 0rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
}
.top_div {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.heading_div {
  width: 76%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}
.head_text > h2 {
  color: #14161b;
  font-family: Be Vietnam Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px; /* 137.5% */
}
.details_top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3rem;
}
.img_box {
  width: 500px;
  height: 270px;
  flex-shrink: 0;
  border-radius: 6px;
  overflow: hidden;
}
.img_box > img {
  width: 100%;
  height: auto;
}
.top__text {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}
.top__text > h3 {
  color: #14161b;
  font-family: Be Vietnam Pro;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px; /* 94.286% */
}
.details_sec {
  width: 76%;
  height: 100%;
  padding: 2rem 3rem;
  background-color: #fff;
  border-radius: 6px;
}
.details__text {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.details__text > h2 {
  font-family: Be Vietnam Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
}
.details__text > p {
  color: #706c74;
  font-family: Be Vietnam Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 160% */
}
.details__text > ol > li {
  list-style: decimal;
  color: #706c74;
  font-family: Be Vietnam Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 160% */
}

.details__text > ol {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* =====================REVIEW BOX STYLE START HERE======================= */

.Reviews_sub_box {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
}
.secnd_div_review {
  width: 40%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.rate_box > h5 {
  color: #0d960a;
  font-family: Be Vietnam Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.therd_div_review {
  width: 50%;
}
.fivestar_div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.text_strt {
  display: grid;
  place-items: center;
  grid-template-columns: 50% 50%;
  gap: 4px;
}
.text_strt h5 {
  color: #14161b;
  font-size: 18px;
  font-weight: 700;

  margin-bottom: 0rem !important;
}
.rating_line_div {
  width: 63%;
  height: 10px;
  border-radius: 10px;
  background: #e8e8e8;
  overflow: hidden;
}
.inner_div {
  background-color: #0d960a;
  height: 10px;
  border-radius: 10px;
}
/*===----=====----====---- 1st model  style start here ===----====----====----====----==== */
.main_model_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub_model_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.model_title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.model_title > h1 {
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px; /* 137.5% */
}
.model_title p {
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
form {
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
}
.name_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.fname,
.Address_box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.fname > label,
.Address_box > label {
  color: #14161b;
  font-family: Be Vietnam Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
::placeholder {
  color: #999;
  font-family: Be Vietnam Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input_div {
  width: 189px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input_div > input {
  width: 180px;
  height: 40px;
  outline: none;
  border: none;
  background: transparent;
}
.Address_input {
  width: 393px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Address_input > input {
  width: 380px;
  height: 40px;
  outline: none;
  border: none;
  background: transparent;
}

.input_div > select {
  width: 180px;
  height: 40px;
  outline: none;
  border: none;
  background: transparent;
}
.model_btn {
  display: flex !important;
  width: 403px !important;
  padding: 13px 25px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px !important;
  border-radius: 4px !important;
  background: #0d960a !important;
  outline: none !important;
  border: none !important;
  cursor: pointer;
}
/* /*===----=====----====---- 2nd model  style start here ===----====----====----====----==== */
.modeltwo_main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
}
.heding_second_model {
  width: 403px !important;
  height: 107px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub_model_2nd_box {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
.sub_model_2nd_box > img {
  width: 102px;
  height: 77px;
  flex-shrink: 0;
  border-radius: 6px;
}
.second_model_head_text > h3 {
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.second_model_head_text > span {
  color: var(--green, #00ad5a);
  text-align: right;
  font-family: Be Vietnam Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.modeltwo_seconddiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.sub_secnd_div {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}
.sub_secnd_div > p {
  text-align: center;
  color: #14161b;
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.btn_model2_div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
.model_btn2 {
  display: flex;
  width: 194px;
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  background: #fff;
  font-family: Be Vietnam Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.model_btn2:hover,
.model_btn3:hover {
  background-color: #00ad5a;
  color: #fff;
}

.model_btn3 {
  display: flex;
  width: 194px;
  padding: 13px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  background: #fff;
  font-family: Be Vietnam Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* /*===----=====----====---- 3rd model  style start here ===----====----====----====----==== */

.modelthree_main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
}

.thard_model_box > p {
  text-align: center;
  color: #14161b;
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.textfild_box {
  width: 100%;
  width: 403px;
  height: 251px;
  border: 1px solid #767478;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.textarea {
  resize: none !important;
  border: none !important;
  outline: none !important;
  background: transparent !important;
  font-size: 15px !important;
}
.btn_model3 {
  display: flex !important;
  width: 197px !important;
  padding: 13px 25px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px !important;
  border-radius: 4px !important;
  border: 1px solid #00ad5a !important;
  background: #fff !important;
  color: #00ad5a !important;
  font-family: Be Vietnam Pro;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.btn_model3:hover {
  background-color: #00ad5a !important;
  color: #fff !important;
}
/* /*===----=====----====---- 4th model  style start here ===----====----====----====----==== */

.four_main_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
}
.fourth_text_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.fourth_text_box > h2 {
  color: #14161b;
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}
.fourth_text_box > p {
  color: var(--black, #14161b);
  text-align: center;
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.mobile_number_input {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}
.mobile_number_input > label {
  color: var(--black, #14161b);
  font-family: Be Vietnam Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.number_input_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 393px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
}
.number_input_div > input {
  width: 385px;
  height: 40px;
  border: none;
  outline: none;
  background: transparent;
  padding: 10px;
}
.disc_text_model {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 391px;

  flex-shrink: 0;
}

.disc_text_model > p {
  color: #6a6a6a;
  font-family: Be Vietnam Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.errors_msg_p {
  font-size: 10px !important;
  color: red !important;
  font-weight: 400 !important;
}
.div_btn_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
/*=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- TABLET AND MOBILE -=-=-=-=-=-=-=-=-= */
@media screen and (max-width: 1024px) {
  .img_box {
    width: 300px;
    height: auto;
  }
  .top__text > h3 {
    font-size: 30px;
    line-height: 8px;
  }
  .Reviews_sec {
    padding: 1rem 0rem;
  }
  .secnd_div_review {
    width: 60%;
  }
  .therd_div_review {
    width: 70%;
  }
}
/*=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-  MOBILE-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
@media screen and (max-width: 600px) {
  .deals_details_sec {
    gap: 2rem;
    padding: 6rem 0rem;
  }
  .details_top {
    flex-direction: column;
    gap: 1rem;
  }
  .details_sec {
    width: 100%;
  }
  .top__text {
    flex-direction: row;
    align-items: center;
  }
  .top__text > h3 {
    font-size: 28px;
    line-height: 0px;
    margin-bottom: 0rem !important;
  }
  .btn5 {
    font-size: 14px;
  }
  .secnd_div_review {
    width: 100%;
  }
  .therd_div_review {
    width: 100%;
  }
  .input_div {
    width: 150px;
  }
  .input_div > input {
    width: 140px;
  }
  .input_div > select {
    width: 140px;
  }
  .Address_box {
    align-items: center;
    width: 100%;
  }
  .Address_box > label {
    width: 100%;
    text-align: left;
  }
  .Address_input {
    width: 100%;
  }
  .Address_input > input {
    width: 90%;
  }
  .model_btn {
    width: 100% !important;
  }
  .heding_second_model {
    width: 100% !important;
  }
  .second_model_head_text > h3 {
    font-size: 15px;
  }
  .model_btn2 {
    width: auto;
    font-size: 10px;
  }
  .model_btn3 {
    width: auto;
    font-size: 10px;
  }
  .textfild_box {
    width: 100%;
  }
  .number_input_div {
    width: 100%;
  }
  .number_input_div > input {
    width: 100%;
  }
  .disc_text_model {
    width: 100%;
    padding: 0.5rem 0rem;
  }
  .model_title > h1 {
    font-size: 20px;
  }

  .btn_model3 {
    width: 145px !important;
  }
}
/* <=============== login div  ===============> */
.main_login_div {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
}

.right_div {
  width: 100%;
  height: 100%;
  background-color: #1a59f3;
  border-radius: 6px 0px 0px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  /* padding: 2rem; */
}
.right_div > img {
  width: 40%;
  aspect-ratio: 3/2;
  object-fit: contain;
}
.left_div {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2.5rem;
  height: 350px;
  width: 100%;
}
/* body.modal-open > :not(.modal) {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
} */

@media screen and (max-width: 600px) {
  .main_login_div {
    width: 100%;

    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
  }
  .right_div {
    width: 100%;
    border-radius: 0px 0px 6px 6px;
    padding: 1rem;
    height: 270px;
  }
  .left_div {
    width: 100%;
    height: 250px;
    gap: 0.5rem;
    height: 300px;
  }
}
