.MyWallet-main-sec {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
}

.MyWallet-main-div {
  display: flex;
  width: 80%;

  flex-direction: column;
}

.Balance-total-div {
  display: flex;
  width: 100%;
  background: #14161b;
  border-radius: 4px;
  height: 138px;
  align-items: center;
  padding: 1rem;
  justify-content: center;
}

.second-totel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.total-kaliya-div {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.rupy-belans-doler {
  color: white;
  font-weight: 800;
  font-size: 42px;
}

.total-span {
  color: white;
  font-weight: 500;
}

.Transfer-btn {
  background: #00ad5a;
  font-weight: 600;
  border-radius: 5px;
  width: 343px;
  height: 45px;
  color: white;
  border: none;
  outline: none;
}

.My-Wallet-hed {
  font-weight: 700;
  font-size: 27px;
  color: #1e2d38;
}

.paymnet-custumar-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.paymnet-add-custumar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.paymnet-income-custumar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.paymnet-add-custuma {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}

.Income-History-span {
  font-size: 24px;
  font-weight: 500;
}
.Payment-Options-cus {
  color: #1e2d38;
  font-weight: 500;
  font-size: 20px;
}

.payment-input-custamar {
  border: 1px solid #e4e4e4;
  width: 413px;
  height: 60px;
  gap: 0.5rem;
  outline: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.custum-line-user {
  width: 100%;
  border: 1px solid #e2e2e2;
  margin-top: 1rem;
}

.Albord-and-name {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.Albord-img {
  width: 60px;
  height: 60px;
}

.flores-span {
  font-weight: 500;
  font-size: 20px;
  color: #1e2d38;
}

.doharzar-and-span {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.doharzar-dater {
  font-weight: 500;
  font-size: 16px;
  color: #1e2d38;
}

.blue-price {
  color: #00ad5a;
  font-weight: 500;
  font-size: 21px;
  font-weight: 500;
}

.bordar-ka-liya {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media screen and (max-width: 885px) {
  .paymnet-custumar-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  .Transfer-btn {
    width: 204px;
  }
  .payment-main-dib90 {
    width: 100%;
  }
  .doharzar-and-span {
    gap: 1rem;
  }
  .Albord-and-name {
    gap: 1rem;
  }
}
@media screen and (max-width: 769px) {
  .paymnet-custumar-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  .Balance-total-div {
    padding: 1px;
  }
}

@media screen and (max-width: 540px) {
  .Transfer-btn {
    width: 100%;
  }

  .MyWallet-main-div {
    width: 90%;
  }

  .paymnet-custumar-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  /* .payment-input-custamar {
        width: 100%;
    } */

  .second-totel {
    display: flex;
    gap: 1rem;
  }

  .rupy-belans-doler {
    font-weight: 700;
    font-size: 32px;
  }
}

@media screen and (max-width: 428px) {
  .Transfer-btn {
    width: 200px;
  }

  .MyWallet-main-div {
    width: 90%;
  }

  .paymnet-custumar-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .payment-input-custamar {
    width: 100%;
  }

  .second-totel {
    display: flex;
    gap: 1rem;
  }

  .rupy-belans-doler {
    font-weight: 700;
    font-size: 32px;
  }
  .Balance-total-div {
    height: 160px;
  }
  .Payment-Options-cus {
    font-size: 15px;
  }
  .Albord-img {
    width: 40px;
    height: 40px;
  }
  .flores-span {
    font-weight: 500;
    font-size: 16px;
    color: #1e2d38;
  }
  .second-totel {
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }
  .jugad-input12 {
    width: 180px;
  }
  .paymnet-add-custumar {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;
  }
}

@media screen and (max-width: 390px) {
  .Income-History-span {
    font-size: 20px;
  }
}

@media screen and (max-width: 375px) {
  .doharzar-dater {
    font-size: 14px;
  }
  .Albord-and-name {
    gap: 0.5rem;
  }
}

@media screen and (max-width: 320px) {
  .Transfer-btn {
    width: 180px;
    font-size: 14px;
  }
  .card_number {
    text-align: start;
    width: 50px;
    height: auto;
  }
  .payment-input67 {
    width: 184px;
  }
  .blue-price {
    color: #00ad5a;
    font-weight: 500;
    font-size: 14px;
    font-weight: 500;
  }
  .Albord-img {
    width: 36px;
    height: 35px;
  }
  .doharzar-dater {
    font-size: 12px;
  }
  .flores-span {
    font-weight: 500;
    font-size: 13px;
    color: #1e2d38;
  }
}
