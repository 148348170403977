.mainlistdiv {
  width: 100%;
  height: 630px;
  /* border: 1px solid #282828; */
  background: #ffffff;
}

.searchlist {
  width: 100%;
  height: 60px;
  background: #272727;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 1rem;
}

.searchlist > div {
  background-color: #202020;
  border: 1px solid #5f5f5f;
}

.chatslist {
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}

.chatslist::-webkit-scrollbar {
  display: none;
}

.listusername {
  display: flex;
  flex-direction: column;
}

.listmaindiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  cursor: pointer;
  border-bottom: 1px solid #434343;
  padding: 10px 10px;
  height: 77px;
}

.activelist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  cursor: pointer;
  /* border-bottom: 1px solid #dbdbdb; */
  padding: 10px 10px;
  height: 77px;
  background-color: #dbdbdb;
}

.listmaindiv:hover {
  background-color: #e6f5ee;
}

.chattime {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.listmaindiv > :nth-child(1) {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chattime > span {
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-align: right;
  color: #706c74;
}

.listusername > p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #706c74;
}

.ppchatbutton {
  display: flex;
  align-items: center;
  width: 100%;
}
.ppchatbutton > .unactivebtn {
  background-color: transparent;
  width: 100%;
  border: none;
  border-bottom: 3px solid #807b7b;
  padding: 10px;
  cursor: pointer;
  color: #635d5d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 600;
  outline: none;
}

.activebtn {
  background-color: transparent;
  width: 100%;
  border: none;
  border-bottom: 3px solid #e6b437;
  padding: 10px;
  cursor: pointer;
  color: #635d5d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 600;
  outline: none;
}

.unactivebtn > span,
.activebtn > span {
  width: 25px;
  height: 25px;
  display: grid;
  font-size: 12px;
  place-items: center;
  color: #fff;
  border-radius: 50%;
}

.countspan {
  width: 20px;
  height: 20px;
  background-color: #e6b437;
  display: grid;
  color: #ffffff !important;
  place-items: center;
  font-size: 10px;
  border-radius: 50%;
}

.useravtar {
  border-radius: 100%;
  /* border: 2px solid grey; */
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.useravtar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.listusername > h3 {
  font-size: 18px;
  font-weight: 700;
  color: #000000;
}

.listusername > div > img {
  width: 20px;
}

.listusername > div {
  display: flex;
  align-items: center;
  /* position: relative; */
}

.listusername > div > div {
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid grey;
  overflow: hidden;
  border-radius: 50%;
}

.listusername > div > div > img {
  width: 100%;
  object-fit: cover;
}

.backbtnforlist {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
}

.backbtnforlist > * {
  padding: 0 !important;
}

.searchlist > img {
  width: 30px;
  cursor: pointer;
}

.stylegroubtn {
  padding: 5px 20px;
  background-color: #d8429e;
  border: none;
  outline: none;
  border-radius: 10px;
  color: #fff;
}

.groupavtardiv {
  position: relative;
  width: 50px;
  height: 50px;
}
.groupicon {
  position: absolute;
  right: 0;
  transform: translate(-0);
  bottom: 0;
}
@media screen and (max-width: 1230px) {
  .mainlistdiv {
    width: 100% !important;
  }
}

@media screen and (max-width: 1030px) {
  .listmaindiv,
  .activelist {
    width: 100%;
    justify-content: space-between;
  }

  /* .useravtar {
    width: 90px;
  } */

  .listusername {
    /* align-self: flex-start; */
    width: 100%;
  }

  .listusername > h3 {
    font-size: 14px;
  }
}

@media screen and (max-width: 540px) {
  .listmaindiv {
    width: 100%;
    justify-content: space-between;
  }
  .mainlistdiv {
    width: 100% !important;
  }
}

@media screen and (max-width: 450px) {
  .listusername > p {
    font-size: 11px;
  }

  .listusername > h3 {
    font-size: 12px;
  }
  .listmaindiv {
    justify-content: space-between;
    width: 100%;
  }
  .chattime > span {
    font-size: 10px;
  }
}
