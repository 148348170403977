.main_work_section {
  width: 100vw;

  padding-top: 5.5rem;
}
.submain_box {
  width: 100%;
}
.heading_container {
  width: 100%;
  height: 200px;
  /* background-color: #1a59f3; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.heading_container > h1 {
  color: rgb(0, 0, 0);
  font-size: 50px;
  text-align: center !important;
  font-family: Be Vietnam Pro !important;
  font-style: normal !important;
  font-weight: 800 !important;
}
.btn_howitwork_box {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main_section_for_containt {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  width: 100%;
}

/*! <====== user section css start here ========>   */
.user_containt_main_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.user_ineer_cotainer {
  padding: 2rem 0;
  width: 90%;
  height: 100% !important;
  /* background-color: aliceblue; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.RHS_setion {
  width: 40%;

  /* background-color: #75e8ff; */
  padding: 3rem 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  gap: 8rem;
}
.MHS_setion {
  width: 20%;
  /* height: 100%; */
  /* background-color: #e375ff; */
}
.LHS_setion {
  width: 40%;
  /* height: 90%; */
  /* background-color: #75e8ff; */
  padding: 3rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 8rem;
}
.Fst_box_img {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem 0;
}
.MHS_setion {
  display: flex;
  align-items: center;
  justify-content: center;
}
.FST_contain_text {
  width: 475px;
  height: 196px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}
.FST_contain_text > h2 {
  color: #14161b;
  font-family: Be Vietnam Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 114%;
}
.no_div {
  position: absolute;
  top: 15px;
  right: 10px;
}
.no_text {
  color: #efefef;
  text-align: right;
  font-family: Be Vietnam Pro;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 114%;
}

.bottom_div {
  width: 100%;
  height: 400px;
  background-color: #1a59f3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom_ineer_div {
  width: 70%;
  height: 80%;
  padding: 4rem 6rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.bottom_ineer_div > h4 {
  color: #a8bff8;
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 114%; /* 20.52px */
  text-transform: uppercase;
}
.bottom_ineer_div > h1 {
  color: #fff;
  font-family: Be Vietnam Pro;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 114%;
}
.bottom_ineer_div > p {
  color: #fff;
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
}

/*! <====== Service Provider section css start here ========>   */
.Serviceprovoder_containt_main_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 850px) {
  .LHS_setion {
    padding: 2rem 0;
    gap: 4rem;
  }
  .RHS_setion {
    padding: 2rem 0;
    gap: 4rem;
  }
  .Fst_box_img > span > svg {
    width: 100px;
    height: 100px;
  }
  .Fst_box_img {
    padding: 0rem;
  }

  .MHS_setion > img {
    width: 20px;
  }
  .FST_contain_text {
    width: 100%;
    height: 100%;
    padding: 1rem;
    gap: 1rem;
  }
  .FST_contain_text > h2 {
    font-size: 12px;
    font-weight: 800;
  }
  .FST_contain_text > p {
    font-size: 8px;
  }
  .no_div {
    top: 10px;
    right: 7px;
  }
  .no_text {
    font-size: 25px;
  }
  .bottom_ineer_div {
    width: 80%;
    padding: 0rem;
  }
  .bottom_ineer_div > h4 {
    font-size: 12px;
  }
  .bottom_ineer_div > h1 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 114%;
  }
  .bottom_ineer_div > p {
    color: #fff;
    font-family: Be Vietnam Pro;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
  }
}
@media screen and (max-width: 450px) {
  .LHS_setion {
    padding: 2rem 0;
    gap: 2rem;
  }
  .RHS_setion {
    padding: 2rem 0;
    gap: 2rem;
  }
  .Fst_box_img > span > svg {
    width: 100px;
    height: 100px;
  }
  .Fst_box_img {
    padding: 0rem;
  }

  .MHS_setion > img {
    width: 20px;
  }
  .FST_contain_text {
    width: 100%;
    height: 100%;
    padding: 1rem;
    gap: 1rem;
  }
  .FST_contain_text > h2 {
    font-size: 12px;
    font-weight: 800;
  }
  .FST_contain_text > p {
    font-size: 8px;
  }
  .no_div {
    top: 10px;
    right: 7px;
  }
  .no_text {
    font-size: 25px;
  }
  .bottom_ineer_div {
    width: 80%;
    padding: 0rem;
  }
  .bottom_ineer_div > h4 {
    font-size: 12px;
  }
  .bottom_ineer_div > h1 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 114%;
  }
  .bottom_ineer_div > p {
    color: #fff;
    font-family: Be Vietnam Pro;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
  }
}
@media screen and (max-width: 360px) {
}
