.imgg-off-Brokyln {
  width: 40px;
}
.Boxxx-lorem-chatt-Main-divv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* ChangePassword.css */
.bottom-right-modal {
  position: fixed !important;
  bottom: 2% !important;
  right: 15% !important;
  margin: 0 !important;
  transform: translate(0, 0) !important;
  background-color: transparent !important;
}
.Brokylyn-andtext-main-divv {
  width: 400px;
}

.Chatt-boxx-secondary-div {
  display: flex;
  gap: 10px;
}

.Boxx-spann-chatt {
  font-size: 20px;
}

.chattt-lorm-spann-divv {
  width: 97%;
  display: flex;
  justify-content: flex-end;
}

.Lorem-spann-tagg-chatboxx {
  width: fit-content;
  height: fit-content;
  font-weight: 600;
  border-radius: 6px;
  background-color: #e6f5ee;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

/* .chattt-Eight-spann-divv {
  width: 42%;
  display: flex;
  justify-content: flex-end;
} */

.Chatt-boxx-divv-secc {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Eight-spann-tagg-chatboxx {
  color: #706c74;
}
.chattt-imgg-yellow-cap {
  height: 30px;
  width: 30px;
}

.Yeahh-broklynn-divv-imgg-main {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  /* padding: 10px 0; */
}

.Lorem-spann-tagg-chatboxx-leftt {
  width: fit-content;
  height: 40px;
  font-weight: 600;
  border-radius: 6px;
  background-color: #00ad5a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 8px;
}
/* .chattt-leftt-Eight-spann-divv {
  width: 22%;
  display: flex;
  justify-content: flex-end;
} */

.Chat-LEft-Right-Main_divv {
  overflow-y: scroll;
  height: 40vh;
}

.Eight-spann-leftt-tagg-chatboxx {
  color: #706c74;
}

.Write-here-in-chatt {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  gap: 20px;
}

.Write-here-text-and-circle-divv {
  width: 300px;
  background-color: #fff;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #d4d4d4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Write-here-text-inputt {
  outline: none;
  border: none;
}

.chattt-imgg-spann-divv-leftt {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 885px) {
  .bottom-right-modal {
    bottom: 1% !important;
    right: 1% !important;
    margin: 0 !important;
  }
}
@media screen and (max-width: 834px) {
  .Write-here-in-chatt {
    gap: 8px;
  }
  .bottom-right-modal {
    bottom: 1% !important;
    right: 2% !important;
    margin: 0 !important;
  }
}

@media screen and (max-width: 600px) {
  .bottom-right-modal {
    width: 100% !important;
    bottom: 2% !important;
    right: 0 !important;
    margin: 0 !important;
  }
  .Brokylyn-andtext-main-divv {
    width: 300px;
  }
}

@media screen and (max-width: 320px) {
  .Brokylyn-andtext-main-divv {
    width: 265px;
  }
}

/*  */
.boxmaindiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* background: #1b1b1b; */
  /* padding: 10px 0; */
}

.boxheader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  height: 60px;
  background: #ffffff;
  /* border-bottom: 1px solid #2d2d2d; */
}

.boxheader > div img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 50%;
}

.chatmessages {
  height: 650px;

  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  background-color: #ffffff;
}
.chatmessages::-webkit-scrollbar,
.chatmessages2::-webkit-scrollbar {
  display: none;
}

.chatmessages > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.chatp {
  background: #00ad5a;
  border-radius: 10px;
  border-top-left-radius: 0px;
  padding: 10px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: max-content;
}
.sent {
  background: #e6f5ee;
  border-radius: 10px;
  border-top-right-radius: 0px;
  padding: 10px;
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: max-content;
}

.chatmessages > div > div > .mestime {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #727272;
}

.sendmessage,
.sendmessage2 {
  width: 100%;
  background: #f4f4f4;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 10px;
}

.sendmessage > textarea {
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  width: 100%;
  /* width: 417px; */
  height: 40px;
  outline: none;
  padding: 10px;
  padding-left: 15px;
  resize: none;
}

.sendmessage > textarea::-webkit-scrollbar {
  display: none !important;
}

.sendmessage > button > img,
.sendmessage2 > button > img {
  cursor: pointer;
}

.sendmessage > button,
.sendmessage2 > button {
  outline: none;
  border: none;
}

/* newchatbox css */

.boxmaindivpaarent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .boxmaindivpaarent2 */

.boxmaindivpaarent2 {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatnavparent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.boxmaindivpaarent2 > div {
  width: 800px;
  height: 100%;
}
.boxmaindivpaarent2 > div > form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.boxmaindivactive {
  display: block;
  width: 100%;
}
.boxheader > :nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.listbtn {
  cursor: pointer;
  background-color: #353c47;
  color: #ffffff;
  padding: 10px 10px;
  border-radius: 10px;
}

.boxheader > :nth-child(1) > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
}

.boxmaindiv2 {
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  overflow: hidden;
}

.chatboxheader > h3 {
  font-size: 25px;
  color: #000000;
}

.chatboxheader {
  width: 100%;
}
.chatmessages > span {
  color: #fff;
  font-size: 12px;
}
@media screen and (max-width: 1230px) {
  .boxmaindiv,
  .chatmessages,
  .sendmessage,
  .boxmaindiv2 > textarea {
    width: 100%;
  }
}
@media screen and (max-width: 1030px) {
  .boxmaindiv {
    width: 100%;
  }

  .boxmaindivpaarent2 {
    height: 100%;
    padding: 2rem 2rem;
  }
}
@media screen and (max-width: 888px) {
  .boxmaindivpaarent2 > div {
    width: 100%;
  }
}
@media screen and (max-width: 540px) {
  .boxmaindivpaarent2 {
    height: 100%;
    padding: 0px 0px;
  }

  .chatboxheader > h3 {
    font-size: 13px;
  }
  .boxheader > :nth-child(1) {
    padding: 10px 10px;
  }
  .listbtn {
    padding: 7px 7px;
    border-radius: 5px;
    font-size: 10px;
  }
  /* .boxmaindiv {
    display: none;
  } */

  /* .boxmaindivpaarent2 > div {
    border: none;
  } */
  .chatnavparent {
    gap: 0;
  }

  .boxmaindivpaarent2 {
    height: 86vh;
  }
  .sendmessage > textarea,
  .sendmessage {
    width: 100%;
  }
  .boxmaindivpaarent2 > div {
    width: 100%;
    height: 100%;
  }
  .sent,
  .chatp {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 377px) {
  .chatmessages {
    height: 440px;
  }
}

/*  */
